import { useUpdateEntity } from "hooks/useUpdateEntity";
import {
  deletePermission,
  getPermission,
  updatePermission,
} from "lib/api/permissions";
import { PermissionForm } from "./PermissionForm";
import { Modal, useModal, useModalOnClose } from "components/Modal";
import { UpdatePermissionDto } from "lib/api/typings";
import { FiTrash } from "react-icons/fi";
import { Loader } from "components/Loader";
import { ApiErrorAlert } from "components/ApiErrorAlert";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { permissionFormConfig } from "./PermissionConfig";

export const UpdatePermission = Modal.create(
  () => {
    const { close } = useModal();
    const {
      isUpdatingRecord,
      isLoadingRecord,
      formik,
      error,
      isFormDisabled,
      isCloseButtonDisbaled,
      isUpdateButtonDisabled,
    } = useUpdateEntity<UpdatePermissionDto>({
      entityName: "Permission",
      formConfig: permissionFormConfig,
      updateApi: updatePermission,
      deleteApi: deletePermission,
      fetchPreviousData: getPermission,
      onDelete: () => close(true),
      onUpdate: () => close(true),
    });

    useModalOnClose(async () => {
      if (isUpdatingRecord) return false;

      if (formik.dirty) {
        return Modal.confirm({});
      }

      return true;
    });

    return (
      <Form formik={formik}>
        <Modal.Title>
          <div className="flex gap-2 items-center">
            {isLoadingRecord && <Loader />}
            Update Permission
          </div>
        </Modal.Title>
        <div className="p-4">
          <ApiErrorAlert error={error} />

          <PermissionForm isDisabled={isFormDisabled} />
        </div>

        <Modal.Actions>
          <Modal.CloseButton disabled={isCloseButtonDisbaled} />

          <div className="flex gap-2 items-center">
            <Button
              type="submit"
              disabled={isUpdateButtonDisabled}
              loading={isUpdatingRecord}
            >
              Update
            </Button>
          </div>
        </Modal.Actions>
      </Form>
    );
  },
  {
    size: "lg",
  }
);
