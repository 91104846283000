import { useCallback } from "react";
import { ApiClient } from "./client";
import {
  CreateUserDto,
  ResetPasswordDto,
  UpdatePasswordDto,
  UpdateUserDto,
  UserControllerCreateResponse,
  UserControllerDeleteResponse,
  UserControllerFindAllParameters,
  UserControllerFindAllResponse,
  UserControllerFindOneResponse,
  UserControllerResetPasswordResponse,
  UserControllerUpdatePasswordResponse,
  UserControllerUpdateResponse,

} from "./typings";
import { useQuery } from "@tanstack/react-query";

export const getUsers = async () => {
  return ApiClient.get<UserControllerFindAllResponse<200>>("/user/find").then(
    (response) => response.data.data
  );
};

export const getUserById = (id: string) => {
  return ApiClient.get<UserControllerFindOneResponse<200>>(`/user/find/id/${id}`).then(
    (res) => res.data.data!
  );
};

const getUsersWithParams = async (params: UserControllerFindAllParameters) => {
  return ApiClient.get<UserControllerFindAllResponse<200>>("user/find", {
    params: {
      filter: JSON.stringify(params.filter),
      sort: JSON.stringify(params.sort),
      pageSize: params.pageSize,
      pageNumber: params.pageNumber,
    },
  }).then((res) => res.data.data);
};

export const useSearchUsers = (params: UserControllerFindAllParameters) => {
  const search = useCallback(() => {
    return getUsersWithParams(params);
  }, [params]);

  return useQuery(["user", params], search);
};

export const createUser = async (data: CreateUserDto) => {
  return await ApiClient.post<UserControllerCreateResponse<201>>(
    "/user/account/create",
    data
  ).then((res) => res.data.data);
};

export const deleteUser = async (id: string) => {
  return ApiClient.delete<UserControllerDeleteResponse<200>>(
    `/user/account/${id}/remove`
  ).then((res) => res.data.data);
};

export const updateUser = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateUserDto;
}) => {
  return ApiClient.patch<UserControllerUpdateResponse<200>>(
    `/user/account/${id}/update`,
    data
  ).then((res) => res.data.data);
};

export const verifyAccount = (account: any) => {
  return ApiClient.patch<UserControllerUpdatePasswordResponse<200>>(
    `/user/account/verify`,
    account
  ).then((res) => res.data.data);
};

export const resetPassword = (credentials: ResetPasswordDto) => {
  return ApiClient.post<UserControllerResetPasswordResponse<200>>(
    `/user/account/password/reset`,
    credentials
  ).then((res) => res.data.data);
};

export const updatePassword = (credentials: UpdatePasswordDto) => {
  return ApiClient.patch<UserControllerUpdatePasswordResponse<200>>(
    `/user/account/password/update`,
    credentials
  ).then((res) => res.data.data);
};
