import * as yup from "yup";

export const RolesFormConfig = {
  initialValues: {
    name: "",
    slug: "",
    permissions: [] as string[],
  },
  validationSchema: yup.object().shape({
    name: yup.string().trim().required("Please enter role name."),
    slug: yup.string(),
    permissions: yup.array().not([0], "Please choose permission."),
  }),
};
