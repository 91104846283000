import { useField } from "formik";
import { useCallback } from "react";

export const useFieldArray = <T = any>(name: string) => {
  const [, { value }, { setValue }] = useField<T[]>(name);

  const push = useCallback(
    (...newVal: T[]) => {
      setValue([...newVal, ...value]);
    },
    [setValue, value]
  );

  const insert = useCallback(
    (index: number, nValue: T) => {
      const copy = [...value];
      copy[index] = nValue;
      setValue(copy);
    },
    [setValue, value]
  );

  const remove = useCallback(
    (index: number) => {
      const firstHalf = value.slice(0, index);
      const secondHalf = value.slice(index + 1);
      setValue([...firstHalf, ...secondHalf]);
    },
    [setValue, value]
  );

  return { remove, push, array: value, insert };
};
