import classNames from "classnames";
import cl from "classnames";
import { useUser } from "GlobalStore";
import { UserResponseDto } from "lib/api/typings";
import { getGravatarUrl } from "lib/gravatar";

export const User = ({
  user,
  direction = "rtl",
  showYouIfCurrentUser = false,
  size = "md",
  inCircle = false,
}: {
  user: UserResponseDto | null;
  direction?: "ltr" | "rtl";
  showYouIfCurrentUser?: boolean;
  size?: "sm" | "md";
  inCircle?: boolean;
}) => {
  const cur = useUser();

  const content = showYouIfCurrentUser && user?.id === cur?.id
    ? "You"
    : `${user?.firstName} ${user?.lastName}`;

  return (
    <div
      className={cl(
        "flex items-center rounded-lg",
        direction === "ltr" && "flex-row-reverse justify-end",
        size === "sm" ? "gap-1" : "gap-2"
      )}
    >
      <span
        className={classNames(
          inCircle && "border rounded-full p-2",
          size === "sm" ? "text-sm" : "text-base"
        )}
      >
        {content}
      </span>

      {!inCircle && (
        <img
          src={getGravatarUrl(user?.email || "")}
          alt="avatar"
          className={classNames(
            "border rounded-full",
            size === "md" && "w-9 h-9",
            size === "sm" && "w-6 h-6"
          )}
        />
      )}
    </div>
  );
};
