import { flexRender, Row, Table as TableType } from '@tanstack/react-table';
import classNames from 'classnames';
import React, { useState } from 'react';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';

interface TableProps<T> {
  table: TableType<T>;
  onRowClick?: (row: T) => void;
  roundedTop?: boolean;
  RenderCollapse?: React.ComponentType<RenderCollapseProps<T>>;
  thClass?: string;
  isAccordion?: boolean;
}
interface MetaHeaderProps {
  headerProps: { [key: string]: any };
}
interface TableRowProps<T> {
  row: Row<T>;
  onRowClick?: (row: T) => void;
  RenderCollapse?: React.ComponentType<RenderCollapseProps<T>>;
}
export interface RenderCollapseProps<T> {
  row: Row<T>;
}
interface TableRowWithAccordionProps<T> extends TableRowProps<T> {
  RenderCollapse?: React.ComponentType<RenderCollapseProps<T>>;
}
const TableRow = <T extends object>({ row, onRowClick }: TableRowProps<T>) => {
  return (
    <tr
      className={classNames(
        'border-t',
        onRowClick && 'cursor-pointer hover:bg-gray-50',
      )}
      onClick={() => onRowClick?.(row.original)}
    >
      {row.getVisibleCells().map((cell) => (
        <td className="p-3 px-4" key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

const TableRowWithAccordion = <T extends object>({
  row,
  onRowClick,
  RenderCollapse,
}: TableRowWithAccordionProps<T>) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <tr
        className={classNames(
          'border-t',
          onRowClick && 'cursor-pointer hover:bg-gray-50',
        )}
        onClick={() => onRowClick?.(row.original)}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <td className="p-3 px-4" key={cell.id}>
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
                isCollapsed,
                setIsCollapsed,
              })}
            </td>
          );
        })}
      </tr>
      {isCollapsed && RenderCollapse && (
        <tr>
          <td colSpan={row.getVisibleCells().length}>
            <RenderCollapse row={row} />
          </td>
        </tr>
      )}
    </>
  );
};

export const Table = <T extends object>({
  table,
  onRowClick,
  roundedTop = false,
  RenderCollapse,
  isAccordion = false,
  thClass,
}: TableProps<T>) => {
  return (
    <div className="w-full border-y overflow-auto">
      <table className="w-full">
        <thead className="bg-gray-50 text-gray-600">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={classNames(
                roundedTop && 'first:rounded-tl-lg first:rounded-tr-lg',
              )}
            >
              {headerGroup.headers.map((header) => (
                <th
                  className={`p-2 px-4 ${thClass ? thClass : ''}`}
                  key={header.id}
                  {...((header.column.columnDef.meta as MetaHeaderProps)
                    ?.headerProps &&
                    (header.column.columnDef.meta as MetaHeaderProps)
                      ?.headerProps)}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer flex items-center gap-1 select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <FiArrowUp />,
                        desc: <FiArrowDown />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.map((row) =>
              isAccordion ? (
                <TableRowWithAccordion
                  row={row}
                  RenderCollapse={RenderCollapse}
                  key={row.id}
                  onRowClick={onRowClick}
                />
              ) : (
                <TableRow
                  row={row}
                  RenderCollapse={RenderCollapse}
                  key={row.id}
                  onRowClick={onRowClick}
                />
              ),
            )}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};
