import { ApiClient } from './client';
import {
  CreateSeoDto,
  SeoControllerCreateResponse,
  SeoControllerFindResponse,
  SeoControllerUpdateResponse,
  UpdateSeoDto,
} from './typings';

export const findSeo = async () => {
  return ApiClient.get<SeoControllerFindResponse<200>>(`/seo/find`).then(
    (res) => res.data?.data,
  );
};

export const createSeo = async (params: CreateSeoDto) => {
  return ApiClient.post<SeoControllerCreateResponse<201>>(
    '/seo/create',
    params,
  ).then((res) => res.data?.data);
};

export const updateSeo = async (payload: UpdateSeoDto) => {
  return ApiClient.patch<SeoControllerUpdateResponse<200>>(
    '/seo/update',
    payload,
  ).then((res) => res.data?.data);
};
