/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { Combobox as ComboboxPrim, Transition } from '@headlessui/react';
import { UseQueryResult } from '@tanstack/react-query';
import cl from 'classnames';
import { useField } from 'formik';
import { noop } from 'lib/noop';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Card } from './Card';
import { Input, InputProps } from './Input';
import { Loader } from './Loader';

interface ComboboxOption<T> {
  label: React.ReactNode;
  value: T;
}

export interface ComboboxProps<T> {
  options: ComboboxOption<T>[];
  inputProps?: InputProps;
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  value?: T;
  keyProp?: string;
  onChange?: (e: T) => void;
  displayValue?: (item: T) => string;
  onInputChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export interface AsyncComboboxProps<
  T extends any[] | undefined,
  Z = any,
  Y extends T extends any[] ? T[0] : undefined = T extends any[]
    ? T[0]
    : undefined,
> extends Omit<ComboboxProps<Z>, 'options' | 'loading'> {
  query: UseQueryResult<T>;
  renderLabel?: (each: Y) => React.ReactNode;
  valueAccessor: (each: Y) => Z;
  labelAccessor: (each: Y) => string;
}

export const Combobox = <T extends any>(props: ComboboxProps<T>) => {
  return (
    <div className="relative">
      <ComboboxPrim<T>
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        disabled={props.disabled}
      >
        {({ open, activeIndex }) => (
          <>
            {/* 
              // @ts-ignore */}
            <ComboboxPrim.Input<typeof Input, T>
              as={Input}
              {...props.inputProps}
              className={cl(
                'transition-[border-radius]',
                open && 'rounded-b-none',
              )}
              suffixIcon={
                props.loading ? (
                  <Loader />
                ) : (
                  <ComboboxPrim.Button
                    className={'border-l border-gray-400 pl-2'}
                  >
                    <FiChevronDown
                      className={cl(
                        'transition-all h-full',
                        open && 'rotate-y-180',
                      )}
                      aria-hidden="true"
                    />
                  </ComboboxPrim.Button>
                )
              }
              displayValue={props.displayValue}
              placeholder={props.placeholder}
              onChange={props.onInputChange || noop}
            >
              <Transition
                show={open}
                as={Fragment}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <ComboboxPrim.Options
                  as={Card}
                  className="absolute max-h-72 overflow-y-auto border left-0 right-0 rounded-t-none border-t-none top-full z-30"
                >
                  {props.options.length < 1 && (
                    <div className="px-3 py-2 text-gray-500 text-center">
                      No result found
                    </div>
                  )}
                  {props.options.map((option, index) => (
                    <ComboboxPrim.Option
                      className={cl(
                        'px-3 py-2 border-b list-none last-of-type:border-b-0 hover:bg-gray-100  cursor-pointer',
                        activeIndex === index && 'bg-gray-100',
                      )}
                      key={
                        props.keyProp
                          ? (option.value as any)?.[props.keyProp]
                          : option.value
                      }
                      value={option.value}
                    >
                      {option.label}
                    </ComboboxPrim.Option>
                  ))}
                </ComboboxPrim.Options>
              </Transition>
            </ComboboxPrim.Input>
          </>
        )}
      </ComboboxPrim>
    </div>
  );
};

export const AsyncCombobox = <
  T extends any[] | undefined,
  Z = any,
  Y extends T extends any[] ? T[0] : undefined = T extends any[]
    ? T[0]
    : undefined,
>({
  query,
  renderLabel,
  valueAccessor,
  labelAccessor,
  displayValue,
  ...props
}: AsyncComboboxProps<T, Z, Y>) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const data = useMemo(() => query.data || [], [query.data]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleOnInputChange = (value: string) => {
    const filter = data.filter((d) => {
      let name = d.displayName || d.name;
      if (!name) {
        name = `${d.firstName} ${d.lastName}`;
      }
      return name.toLowerCase().includes(value.toLowerCase());
    });
    const optionData = filter.length > 0 ? filter : [];
    setFilteredData(optionData);
  };

  const options = useMemo(
    () =>
      filteredData.map((each: any) => ({
        label: renderLabel?.(each) || labelAccessor(each),
        value: valueAccessor(each),
      })),
    [filteredData, labelAccessor, renderLabel, valueAccessor],
  );

  let displayValueHandler = useCallback(
    (currentValue: Z) => {
      const selected = data.find(
        (each) => valueAccessor(each) === currentValue,
      );
      return selected ? labelAccessor(selected) : '';
    },
    [data, labelAccessor, valueAccessor],
  );
  if (displayValue) displayValueHandler = displayValue;

  return (
    <Combobox<Z>
      loading={query.isFetching}
      options={options || []}
      displayValue={displayValueHandler}
      onInputChange={(e) => handleOnInputChange(e.target.value)}
      {...props}
    />
  );
};

export const FormikCombobox = <T extends any>(
  props: ComboboxProps<T> & { name: string },
) => {
  const [field, meta, helpers] = useField<T>({
    name: props.name,
  });

  return (
    <Combobox
      {...props}
      name={field.name}
      onChange={(e) => {
        helpers.setValue(e);
        helpers.setTouched(true);
      }}
      value={field.value}
      inputProps={{
        ...props.inputProps,
        hasError: !!meta.touched && !!meta.error,
        errorMessage: meta.error,
      }}
    />
  );
};

export const FormikAsyncCombobox = <
  T extends any[] | undefined,
  Z = any,
  Y extends T extends any[] ? T[0] : undefined = T extends any[]
    ? T[0]
    : undefined,
>(
  props: AsyncComboboxProps<T, Z, Y> & { name: string },
) => {
  const [field, meta, helpers] = useField<Z>({
    name: props.name,
  });

  return (
    <AsyncCombobox
      {...props}
      name={field.name}
      onChange={(e) => {
        helpers.setValue(e);
        helpers.setTouched(true);
      }}
      value={field.value}
      inputProps={{
        ...props.inputProps,
        hasError: !!meta.touched && !!meta.error,
        errorMessage: meta.error,
      }}
    />
  );
};
