import * as yup from 'yup';

type Base = 'KM' | 'MI';

export const PriceRuleConfig = {
  initialValues: {
    unit: '' as Base,
    price: 0.0,
    tax: 0.0,
    extraFee: 0,
    fixCode: '',
    fixAmount: 0,
    // percentCode: '',
    // percentAmount: 0
  },
  validationSchema: yup.object().shape({
    unit: yup.string().oneOf(['KM', 'MI']).required('Please choose unit.'),
    price: yup.number().required('Please enter price to calculate per unit.'),
    tax: yup.number(),
    extraFee: yup.number(),
    fixCode: yup.string().trim().when('fixAmount', {
      is: (value: number) => !!value,
      then: () => yup.string().trim().required('Voucher code is invalid or missing.')
    }),
    fixAmount: yup.number(),
    
    // when('fixCode', {
    //   is: (value: string) => !!value,
    //   then: () => yup.number().positive().required('Voucher amount is invalid or missing.')
    // }),
    // percentCode: yup.string().trim(),
    // percentAmount: yup.number()
  }),
};
