import cl from "classnames";
import React from "react";

export type CardProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const Card = React.forwardRef<HTMLDivElement, CardProps>(({ className, ...props }, ref) => {
  return (
    <div
      className={cl("shadow rounded-lg bg-white max-w-6xl", className)}
      ref={ref}
      {...props}
    />
  );
});
