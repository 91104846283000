import { getPermissions } from "lib/api/permissions";
import { Tag } from "components/Tag";
import { AsyncCombobox } from "components/Combobox";
import { useQuery } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { useFieldArray } from "hooks/useFieldArray";
import { CreateRoleDto, PermissionResponseDto } from "lib/api/typings";

export const PermissionSelector = <T extends CreateRoleDto>({
  isDisabled = false,
}: {
  isDisabled?: boolean;
}) => {
  const form = useFormikContext<T>();
  const { push, remove } = useFieldArray<string>("permissions");

  const query = useQuery(["permission"], getPermissions, {
    select(data) {
      return (data || []).filter((each: PermissionResponseDto) => {
        return !form.values.permissions?.includes(each.slug);
      });
    },
  });

  const { data: permissions } = useQuery(["permission"], getPermissions, {});

  return (
    <div>
      <div className="pt-4 grid grid-cols-2 gap-4">
        <AsyncCombobox
          query={query}
          inputProps={{ label: "Permission" }}
          name="permissions"
          onChange={(val) => push(val)}
          placeholder="Select Permission..."
          valueAccessor={(data) => data.slug}
          labelAccessor={(data) => `${data.name}`}
          disabled={isDisabled}
        />
      </div>
      <div className="pt-4 flex flex-wrap gap-2">
        {form.values.permissions?.map((slug: string, index: number) => {
          const permission = permissions?.find((p: PermissionResponseDto) => p.slug === slug);

          return (
            <Tag key={index} hasRemoveBtn onDelete={() => remove(index)}>
              {permission?.name || slug}
            </Tag>
          );
        })}
      </div>
    </div>
  );
};
