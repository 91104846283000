import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Form } from 'components/Form';
import { ApiErrorAlert } from 'components/ApiErrorAlert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { RuleFormFields } from './RuleFormFields';
import { PriceRuleConfig } from './PriceRuleConfig';
import {
  createPriceRuleMutation,
  findPriceRules,
  updatePriceRuleMutation,
} from 'lib/api/rule';
import { CreatePriceRuleDto, UpdatePriceRuleDto } from 'lib/api/typings';
import { useCallback } from 'react';

export const PriceRuleListing = () => {
  const queryClient = useQueryClient();

  const { data: record, isLoading: isLoadingRecord } = useQuery(
    ['rule'],
    findPriceRules,
  );

  const {
    mutateAsync: createPricerule,
    isLoading: isCreatingRole,
    error,
  } = useMutation(createPriceRuleMutation, {
    onSuccess: () => queryClient.invalidateQueries(['rule']),
  });

  const { isLoading: isUpdatingRecord, mutateAsync: update } = useMutation(
    updatePriceRuleMutation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rule']);
      },
    },
  );

  const submitProcessHandler = useCallback(
    async (data: UpdatePriceRuleDto | CreatePriceRuleDto) => {
      if (record) {
        update({ id: record?.id as string, payload: data });
      } else {
        await createPricerule(data as CreatePriceRuleDto);
      }
    },
    [record, update, createPricerule],
  );

  const form = useFormik<CreatePriceRuleDto | UpdatePriceRuleDto>({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: true,
    ...PriceRuleConfig,
    initialValues: record ? { ...record } : PriceRuleConfig.initialValues,
    onSubmit: submitProcessHandler,
  });

  return (
    <>
      <Card>
        <div className="flex p-3 items-center justify-between">
          <h4 className="font-bold text-xl">Price Rule</h4>
        </div>

        <Form formik={form}>
          <div className="p-4">
            <ApiErrorAlert error={error} />

            <RuleFormFields
              isDisabled={isCreatingRole || isLoadingRecord || isUpdatingRecord}
            />
          </div>

          <div className="flex gap-3 justify-end">
            <div className="p-4">
              <Button
                type="submit"
                loading={isCreatingRole || isLoadingRecord || isUpdatingRecord}
              >
                {!isLoadingRecord && !record ? 'Create' : 'Update'}
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </>
  );
};
