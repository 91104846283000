import { useLoggedIn } from "GlobalStore";
import { Navigate, Outlet } from "react-router-dom";

type GuardProps = {
  children?: React.ReactNode;
  guard?: "authenticated" | "unauthenticated";
};

export const Guard = ({ children, guard = "authenticated" }: GuardProps) => {
  const isAuth = useLoggedIn();

  if (guard === "authenticated" && !isAuth) {
    return <Navigate replace to="/auth/sign-in" />;
  }

  if (guard === "unauthenticated" && isAuth) {
    return <Navigate replace to="/app" />;
  }

  return <Outlet />;
};
