import cl from "classnames";
import { useField } from "formik";
import React, { ForwardedRef } from "react";
import { FiAlertOctagon } from "react-icons/fi";

type InputType =
  | React.InputHTMLAttributes<HTMLInputElement>["type"]
  | "textarea";

type HtmlAttributes<T> = T extends "textarea"
  ? React.TextareaHTMLAttributes<HTMLTextAreaElement>
  : React.InputHTMLAttributes<HTMLInputElement>;

export type InputProps<T extends InputType = "text"> = HtmlAttributes<T> & {
  className?: string;
  hasError?: boolean;
  errorMessage?: string;
  type?: T;
  label?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
};

const _Input = <T extends InputType = "text">(
  {
    className,
    hasError,
    errorMessage,
    label,
    prefixIcon,
    suffixIcon,
    type,
    children,
    ...restProps
  }: InputProps<T>,
  ref: ForwardedRef<
    T extends "textarea" ? HTMLTextAreaElement : HTMLInputElement
  >
) => {
  const classNames = cl(
    className,
    "p-2 focus:outline-none w-full border-2  rounded-lg",
    type === "checkbox" &&
      "appearance-none !w-4 h-4 focus:checked:bg-gray-900 ring-offset-2 checked:bg-gray-900 bg-gray-200 p-2 focus:ring-2 ring-gray-900 rounded",
    hasError &&
      "bg-rose-50 focus:bg-rose-100 border-rose-500 focus:border-rose-500  placeholder:text-rose-300 text-rose-500",
    prefixIcon && "pl-7",
    suffixIcon && "pr-7"
  );

  return (
    <label className="flex  flex-col">
      {label && <span className="font-medium mb-1 text-gray-600"> {label}</span>}
      <div className="relative flex gap-2 items-center">
        {type === "textarea" ? (
          <textarea
            className={classNames}
            {...(restProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>) }
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
          />
        ) : (
          <input
            type={type}
            className={classNames}
            {...(restProps as React.InputHTMLAttributes<HTMLInputElement>) }
            ref={ref as ForwardedRef<HTMLInputElement>}
          />
        )}

        <div className="absolute inset-y-0 left-0 flex items-center pl-2">
          {prefixIcon}
        </div>

        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          {suffixIcon}
        </div>

        {children}
      </div>
      {hasError && (
        <div className="flex text-xs items-center gap-1 ml-1 mt-1 border-transparent text-rose-500">
          <FiAlertOctagon className="text-sm" />
          <span className="font-medium">{errorMessage}</span>
        </div>
      )}
    </label>
  );
};

export const Input = React.forwardRef(_Input);

export const FormikInput = <T extends string | number, Y extends InputType>(
  props: InputProps<Y> & { name: string }
) => {
  const [field, meta] = useField<T>(props);

  return (
    <Input
      {...field}
      {...props}
      hasError={!!meta.touched && !!meta.error}
      errorMessage={meta.error}
    />
  );
};
