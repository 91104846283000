import { AppLayout } from 'layout/AppLayout';
import { FiUsers, FiLock } from 'react-icons/fi';
import { BsKey } from 'react-icons/bs';
import { Outlet } from 'react-router-dom';
import { Sidebar } from 'components/Sidebar';
import { useHasPermission } from 'hooks/useHasPermission';
import { IoCarOutline } from 'react-icons/io5';
import { MdPriceCheck } from 'react-icons/md';
import { BiTransferAlt } from 'react-icons/bi';
import { TbSeo } from 'react-icons/tb';

export const SettingsLayout = () => {
  const hasRolePerm = useHasPermission('read-role');
  const hasPermissionPerm = useHasPermission('read-permission');
  const hasBookingPerm = useHasPermission('read-booking');
  const hasACLPermission = hasRolePerm || hasPermissionPerm;

  return (
    <AppLayout>
      <div className="mt-8 flex items-start gap-5">
        <Sidebar>
          {useHasPermission('read-user') && (
            <Sidebar.Item icon={<FiUsers />} title="Users" to="users" />
          )}
          <Sidebar.Item
            title="Vehicles"
            icon={<IoCarOutline />}
            to="vehicles"
          />
          <Sidebar.Item
            title="Price Rule"
            icon={<MdPriceCheck />}
            to="price-rule"
          />
          {hasACLPermission && (
            <Sidebar.Item
              icon={<FiLock />}
              title="Access Control"
              to="access-control"
            >
              {hasRolePerm && (
                <Sidebar.Item
                  icon={<FiUsers />}
                  title="Roles"
                  to="access-control/roles"
                />
              )}

              {hasPermissionPerm && (
                <Sidebar.Item
                  icon={<BsKey />}
                  title="Permissions"
                  to="access-control/permissions"
                />
              )}
            </Sidebar.Item>
          )}
          {hasBookingPerm && (
            <Sidebar.Item
              icon={<BiTransferAlt />}
              title="Bookings"
              to="bookings"
            />
          )}
          <Sidebar.Item
            icon={<TbSeo />}
            title="SEO"
            to="search-engine-optimization"
          />
        </Sidebar>
        <div className="flex-1 ">
          <Outlet />
        </div>
      </div>
    </AppLayout>
  );
};
