import { Card } from "components/Card";
import { AppLayout } from "layout/AppLayout";

export const InProgress = () => {
  return (
    <AppLayout>
      <div className="flex justify-center items-center h-[84vh]">
        <Card className="p-5">
          <h1 className="text-2xl font-bold">
            IN PROGRESS
          </h1>
          <p>This page is currently under development</p>
        </Card>
      </div>
    </AppLayout>
  );
};
