import create from "zustand/vanilla";
import createReactHook from "zustand";
import { persist } from "zustand/middleware";
import { UserResponseDto } from "lib/api/typings";

interface IGlobalStoreState {
  auth: {
    token: string | null;
    user: UserResponseDto | null;
  };
}

interface IGlobalStoreActions {
  saveUser: (user: UserResponseDto) => void;
  logout: () => void;
}

export type IGlobalStore = IGlobalStoreState & { actions: IGlobalStoreActions };

export const GlobalStore = create<IGlobalStore>()(
  persist(
    (set) => ({
      auth: {
        token: null,
        user: null,
      },
      departments: [],
      actions: {
        saveUser: (user: UserResponseDto) => {
          set((state) => ({
            ...state,
            auth: {
              ...state.auth,
              user,
            },
          }));
        },
        logout: () => {
          set((state) => ({
            ...state,
            auth: {
              token: null,
              user: null,
            },
          }));
        },
      },
    }),
    {
      name: "global-store",
      partialize: (state) => {
        return {
          auth: state.auth,
        };
      },
    }
  )
);

export const useGlobalStore = createReactHook(GlobalStore);

export const useLoggedIn = () => useGlobalStore((state) => !!state.auth.token);
export const useUser = () => useGlobalStore((state) => state.auth.user);
export const useStoreActions = () => useGlobalStore((state) => state.actions);
export const useIsSuperUser = () =>
  useGlobalStore((state) => state.auth.user?.isSuperAdmin);
