import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { FormikInput } from 'components/Input';
import { Form } from 'components/Form';
import { useFormik } from 'formik';
import { useSignIn } from 'lib/api/auth';
import { SignInDto } from 'lib/api/typings';
import { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useStoreActions as useGlobalStoreActions } from 'GlobalStore';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

export const SignIn = () => {
  const { saveUser } = useGlobalStoreActions();
  const captchaRef = useRef<any>(null);

  const {
    mutate: signIn,
    isError,
    isLoading,
    error,
  } = useSignIn({
    onError: (error) => {
      if (typeof error.response?.data?.message !== 'string') {
        formik.setErrors(error.response?.data?.message);
      }
      console.error(error);
    },
    onSuccess: (res) => {
      if (res.data) return saveUser(res.data);
    },
  });

  const options = {
    animationData: require('./loginAnimation.json'),
    loop: true,
  };

  const onSubmit = useCallback(
    (values: SignInDto) => {
      let token = null;
      if (captchaRef && captchaRef.current) {
        token = captchaRef.current.getValue();
        captchaRef?.current?.reset();
      }

      if (!token) return;

      signIn(values);
    },
    [signIn, captchaRef],
  );

  const formik = useFormik<SignInDto>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .trim()
        .email()
        .required('Please enter the email address.'),
      password: yup.string().trim().required('Please enter the password.'),
    }),
    onSubmit,
  });

  return (
    <div className="lg:flex bg-white h-screen items-center justify-center scrollbar-hide ">
      <div className="lg:w-1/2 xl:max-w-screen-md bg-white ">
        <div className=" flex flex-col  px-12 sm:px-24 pt-6 lg:pt:0 md:px-48 lg:px-12  lg:mt-6 xl:px-24 xl:max-w-2xl ">
          <h2 className="text-center text-4xl text-gray-600 font-display font-semibold lg:text-left xl:text-5xl xl:text-bold">
            Log in
          </h2>
          <div className="mt-4">
            {isError && typeof error.response?.data?.message === 'string' && (
              <Alert
                type="error"
                title="Error"
                description={error?.response?.data?.message}
              />
            )}
          </div>
          <div className="mt-12">
            <Form formik={formik}>
              <div>
                <FormikInput
                  placeholder="test@example.com"
                  name="email"
                  type="email"
                  label="Email"
                  className="w-full text-lg py-2  border-gray-300 focus:outline-none focus:border-primary"
                  disabled={isLoading}
                />
              </div>
              <div className="mt-8">
                <FormikInput
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="••••••••••••"
                  disabled={isLoading}
                  className="w-full text-lg py-2  border-gray-300 focus:outline-none focus:border-primary"
                  autoComplete="on"
                />
              </div>
              <div className="mt-10">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                  ref={captchaRef}
                  className="mb-4"
                />
                <Button
                  className="bg-primary text-gray-100 px-4 py-3 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-primaryHover shadow-lg"
                  loading={isLoading}
                  type="submit"
                >
                  Login
                </Button>
              </div>
            </Form>
            <div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
              <Link to="/auth/forgot-password">
                <span className="text-gray-500 hover:text-primaryHover ">
                  Forget your password?
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center pt-12 pb-2 lg:hidden ">
          <img src="/logo.png" alt="Example" height="80px" width="150px" />
          <h3 className="text-gray-500 mb-12 mt-4 text-sm">
            Copyright © 2023, Edinburgh Transfers. All Rights Reserved.
          </h3>
        </div>
      </div>
      <div
        className="hidden lg:flex flex-col items-center justify-between  flex-1 h-screen bg-slate-50"
        style={{ backgroundImage: 'url(/logoBack.png)' }}
      >
        <div className="flex  flex-col items-center justify-center h-5/6 mt-20 ">
          <div className="text-4xl text-gray-700 tracking-wide font-semibold mb-16">
            Edinburgh<b className="text-logo">Transfers</b>
          </div>
          <div className="w-[350px] h-[350px]">
            <Lottie animationData={options.animationData} loop={true} />
          </div>
        </div>

        <img src="/logo.png" alt="Example" height="90px" width="170px" />
        <h3 className="text-gray-500 mb-12 mt-4">
          Copyright © 2024, Edinburgh Transfers. All Rights Reserved.
        </h3>
      </div>
    </div>
  );
};
