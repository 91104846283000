import { ApiErrorAlert } from "components/ApiErrorAlert";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { Loader } from "components/Loader";
import { Modal, useModal, useModalOnClose } from "components/Modal";
import { useUpdateEntity } from "hooks/useUpdateEntity";
import { UpdateUserDto } from "lib/api/typings";
import { deleteUser, getUserById, updateUser } from "lib/api/user";
import { useCallback } from "react";
import { FiTrash } from "react-icons/fi";
import { UserConfigForm } from "./UserConfig";
import { UserForm } from "./UserFormFields";
import { useUser } from "GlobalStore";
import { useHasPermission } from "hooks/useHasPermission";

export const UpdateUser = Modal.create(
  () => {
    const getUser = useCallback(async (id: string) => {
      return getUserById(id).then((data) => ({
        ...data,
        roles: data?.roles?.map((role) => role.slug) || [],
      }));
    }, []);

    const currentUser = useUser();
    const { close } = useModal();

    const {
      isUpdatingRecord,
      error,
      formik,
      handleDeleteButtonClk,
      isCloseButtonDisbaled,
      isDeleteButtonDisabled,
      isDeletingRecord,
      isFormDisabled,
      isLoadingRecord,
      isUpdateButtonDisabled,
      record,
    } = useUpdateEntity<UpdateUserDto>({
      formConfig: UserConfigForm,
      entityName: "user",
      deleteApi: deleteUser,
      updateApi: updateUser,
      fetchPreviousData: getUser,
      onDelete: () => close(true),
      onUpdate: () => close(true),
    });

    const hasDeletePermission = useHasPermission("delete-user");
    const hasUpdatePermission = useHasPermission("update-user");

    const disableForLoggedIn =
      record && currentUser
        ? currentUser.id === record.id || isDeleteButtonDisabled
        : false;

    const isSuperAdminData = record?.isSuperAdmin;

    const isRoleDisabled = !currentUser?.roles?.some(({ permissions }) => {
      return permissions?.some((perm) => perm.slug.includes("assign"));
    });

    useModalOnClose(() => {
      if (isUpdatingRecord) return false;

      if (formik.dirty) return Modal.confirm({});

      return true;
    });

    return (
      <Form formik={formik}>
        <Modal.Title>
          <div className="flex gap-2 items-center">
            {isLoadingRecord && <Loader />}
            Update User
          </div>
        </Modal.Title>
        <div className="p-4">
          <ApiErrorAlert error={error} />

          <UserForm
            isDisabled={isFormDisabled}
            hidePasswordField={true}
            disableRoles={disableForLoggedIn || isRoleDisabled}
          />
        </div>
        <Modal.Actions>
          <Modal.CloseButton
            disabled={isCloseButtonDisbaled}
          ></Modal.CloseButton>
          <div className="flex gap-2 items-center">
            <Button
              type="button"
              variant="secondary"
              className="text-rose-500"
              icon={<FiTrash />}
              loading={isDeletingRecord}
              onClick={handleDeleteButtonClk}
              disabled={
                disableForLoggedIn || isSuperAdminData || !hasDeletePermission
              }
            >
              Delete
            </Button>
            <Button
              type="submit"
              disabled={isUpdateButtonDisabled || !hasUpdatePermission}
              loading={isUpdatingRecord}
            >
              Update
            </Button>
          </div>
        </Modal.Actions>
      </Form>
    );
  },
  { size: "lg" }
);
