import { useUser } from 'GlobalStore';
import { useNextPath } from './useNextPath';

export const useHasPermission = (type: string): any => {
  const user = useUser();

  const hasPermission = user?.roles?.some((role) => {
    return role.permissions?.some((permission) => {
      return permission.slug === type;
    });
  });

  return !!hasPermission;
};

export const usePermission = () => {
  const hasRolePermission = useHasPermission('read-role');
  const hasPermissionAccess = useHasPermission('read-permission');
  const hasBookingPermission = useHasPermission('read-booking');

  return {
    hasRolePermission: hasRolePermission,
    hasPermissionAccess: hasPermissionAccess,
    hasACLPermission: hasPermissionAccess || hasRolePermission,
    showSettings: useNextPath() === '/app/dashboard',
    nextPath: useNextPath(),
    hasReadPermission: useHasPermission('read-user'),
    hasDepartmentPermission: useHasPermission('read-department'),
    hasWorkflowPermission: useHasPermission('read-workflow'),
    hasRequestPermission: useHasPermission('read-request'),
    hasBookingPermission,
  };
};
