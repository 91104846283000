import * as yup from 'yup';

export const SeoFormConfig = {
  initialValues: {
    main: {
      title: '',
      keywords: '',
      description: '',
    },
    booking: {
      title: '',
      keywords: '',
      description: '',
    },
  },
  validationSchema: yup.object().shape({
    main: yup.object().shape({
      title: yup
        .string()
        .trim()
        .required('Please enter a meta title for main page.'),
      keywords: yup.string().trim().required('Please enter the meta keywords.'),
      description: yup
        .string()
        .trim()
        .required('Please enter meta description.'),
    }),
    booking: yup.object().shape({
      title: yup
        .string()
        .trim()
        .required('Please enter a meta title for bookings page.'),
      keywords: yup.string().trim().required('Please enter the meta keywords.'),
      description: yup
        .string()
        .trim()
        .required('Please enter meta description.'),
    }),
  }),
};
