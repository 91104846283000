import { useModal } from '@ebay/nice-modal-react';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from './Modal';

export const OpenModal = <T extends object>({
  modal,
  props,
  backUrl = '..',
}: {
  modal: React.ComponentType<T>;
  backUrl?: string;
  props?: Omit<T, 'id'>;
}) => {
  const modalControls = useModal(modal as React.FC<T>);
  const shouldNavigateBack = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    Modal.show(modal as React.FC<T>, props);

    return () => {
      Modal.hide(modal as React.FC<T>);
    };
  }, [modal, props]);

  useEffect(() => {
    if (modalControls.visible) {
      shouldNavigateBack.current = true;
    } else if (shouldNavigateBack.current) {
      navigate(backUrl, {
        replace: true,
      });
    }
  }, [backUrl, modalControls.visible, navigate]);

  return null;
};
