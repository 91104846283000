import { AxiosError } from 'axios';
import { Alert } from './Alert';

export const ApiErrorAlert = ({ error }: { error: unknown }) => {
  const _error = ((error as AxiosError)?.response?.data as Error)?.message;

  return (
    <>
      {!!_error && (
        <div className='mb-3'>
          <Alert
            description={
              typeof _error === 'object'
                ? 'Please report this error to the system administrator'
                : _error
            }
          />
        </div>
      )}
    </>
  );
};
