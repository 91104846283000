import axios, { AxiosError, AxiosResponse } from "axios";
import { GlobalStore } from "GlobalStore";

export const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

ApiClient.interceptors.request.use((config) => {
  const {
    auth: { token },
  } = GlobalStore.getState();

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

const handleSetAccesSTokenHeader = (res: AxiosResponse | AxiosError) => {
  let setToken: string | undefined;

  if (res instanceof axios.AxiosError) {
    if (res.response) {
      setToken = res.response.headers?.["token"];
    }
  } else {
    setToken = res.headers?.["token"];
  }

  if (setToken !== undefined) {
    GlobalStore.setState((store) => {
      return {
        ...store,
        auth: {
          ...store.auth,
          token: setToken || null,
          user: null,
        },
      };
    });
  }

  if (res instanceof axios.AxiosError) {
    return Promise.reject(res);
  }

  return res;
};

ApiClient.interceptors.response.use(
  handleSetAccesSTokenHeader,
  handleSetAccesSTokenHeader
);
