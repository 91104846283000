import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { Modal, useModal, useModalOnClose } from "components/Modal";
import { ApiErrorAlert } from "components/ApiErrorAlert";
import { useFormik } from "formik";
import { VehiclesConfig } from "./VehicleConfig";
import { VehicleForm } from "./VehicleFormFields";
import { CreateVehicleDto } from "lib/api/typings";
import { createVehicle } from "lib/api/vehicles";

export const NewVehicle = Modal.create(
  () => {
    const { close } = useModal();
    const queryClient = useQueryClient();

    const {
      isLoading,
      error,
      mutateAsync: create,
    } = useMutation(createVehicle, {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["vehicle"]);
      },
    });

    const form = useFormik<CreateVehicleDto>({
      ...VehiclesConfig,
      async onSubmit(data) {
        await create(data);
        close(true);
      },
    });

    useModalOnClose(() => {
      if (isLoading) return false;

      if (form.dirty) {
        return Modal.confirm({});
      }

      return true;
    });

    return (
      <Form formik={form}>
        <Modal.Title>Add New Vehicle</Modal.Title>
        <div className="p-4">
          <ApiErrorAlert error={error} />

          <VehicleForm isDisabled={isLoading} errors={form.errors} />
        </div>
        <Modal.Actions>
          <Modal.CloseButton disabled={isLoading}></Modal.CloseButton>
          <Button type="submit" loading={isLoading}>
            Create
          </Button>
        </Modal.Actions>
      </Form>
    );
  },
  { size: "lg" }
);
