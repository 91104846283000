import { useMutation } from '@tanstack/react-query';
import { ApiErrorAlert } from 'components/ApiErrorAlert';
import { verifyAccount } from 'lib/api/user';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const AccountVerification = () => {
  const param = useParams();
  const token = param.token || '';

  const { mutateAsync: verify, isError, error } = useMutation(verifyAccount);

  useEffect(() => {
    verify({ token: token });
  }, [verify, token]);

  const content = !isError ? (
    <p>Your account has been verified successfully.</p>
  ) : (
    <ApiErrorAlert error={error} />
  );

  return (
    <div className="flex justify-center h-screen flex-1 items-center">
      <div className="flex gap-4 flex-col p-8 rounded-lg bg-white border w-96">
        {content}
      </div>
    </div>
  );
};
