import { FormikInput } from "components/Input";

export const PermissionForm = ({ isDisabled }: { isDisabled: boolean }) => {
  return (
    <div className=" grid grid-cols-2 gap-4">
      <FormikInput
        name="name"
        label="Permission Name"
        placeholder="Editor"
        disabled={isDisabled}
      />

      <FormikInput
        name="slug"
        label="Slug"
        placeholder="editor"
        disabled={true}
      />
    </div>
  );
};
