import moment from "moment";

export const formatDate = (date: string) => {
  return moment(date).format("Do MMM YYYY, h:mm A");
};

export const formatScheduleDate = (date: string) =>
  moment(date.slice(0, 19)).format("hh:mm A, Do MMM YYYY");

export const timeFromX = (date: string) => {
  return moment(date).fromNow();
};
