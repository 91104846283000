import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiErrorAlert } from 'components/ApiErrorAlert';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Form } from 'components/Form';
import { FormikInput } from 'components/Input';
import { useFormik } from 'formik';
import { createSeo, findSeo, updateSeo } from 'lib/api/seo';
import { useCallback } from 'react';
import { SeoFormConfig } from './SeoFormConfig';
import { CreateSeoDto, UpdateSeoDto } from 'lib/api/typings';

export const UpdateCreateSeo = () => {
  const queryClient = useQueryClient();

  const { data: record, isLoading: isLoadingRecord } = useQuery(
    ['seo'],
    findSeo,
  );

  const {
    mutateAsync: createPricerule,
    isLoading: isCreatingSeo,
    error,
  } = useMutation(createSeo, {
    onSuccess: () => queryClient.invalidateQueries(['seo']),
  });

  const { isLoading: isUpdatingRecord, mutateAsync: update } = useMutation(
    updateSeo,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['seo']);
      },
    },
  );

  const submitProcessHandler = useCallback(
    async (data: CreateSeoDto | UpdateSeoDto) => {
      if (Object.keys(record || {}).length > 0) {
        update(data);
      } else {
        await createPricerule(data as CreateSeoDto);
      }
    },
    [record, createPricerule, update],
  );

  const form = useFormik<CreateSeoDto | UpdateSeoDto>({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    ...SeoFormConfig,
    initialValues: record ? { ...record } : SeoFormConfig.initialValues,
    onSubmit: submitProcessHandler,
  });

  const isDisabled = isUpdatingRecord || isLoadingRecord || isCreatingSeo;

  return (
    <>
      <Card>
        <div className="flex p-3 items-center justify-between">
          <h4 className="font-bold text-xl">Search Engine Optimization</h4>
        </div>

        <Form formik={form}>
          <div className="p-4">
            <ApiErrorAlert error={error} />

            <div>
              <h5 className="font-bold text-xl">Main Page</h5>
              <div className="mt-2">
                <div className="grid grid-cols-2 gap-4">
                  <FormikInput
                    name="main.title"
                    label="Meta Title"
                    placeholder="Page title e.g. Ednburgh Transfers - Your Gateway to World"
                    disabled={isDisabled}
                  />
                  <FormikInput
                    name="main.keywords"
                    label="Meta Keywords"
                    placeholder="Your keywords separated by a comma"
                    disabled={isDisabled}
                  />
                </div>

                <div className="grid-cols-1 mt-4">
                  <FormikInput
                    type="textarea"
                    name="main.description"
                    label="Meta Description"
                    placeholder="Meta description"
                    disabled={isDisabled}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <h5 className="font-bold text-xl">Bookings Page</h5>
              <div className="mt-2">
                <div className="grid grid-cols-2 gap-4">
                  <FormikInput
                    name="booking.title"
                    label="Meta Title"
                    placeholder="Page title e.g. Ednburgh Transfers - Your Gateway to World"
                    disabled={isDisabled}
                  />
                  <FormikInput
                    name="booking.keywords"
                    label="Meta Keywords"
                    placeholder="Your keywords separated by a comma"
                    disabled={isDisabled}
                  />
                </div>

                <div className="grid-cols-1 mt-4">
                  <FormikInput
                    type="textarea"
                    name="booking.description"
                    label="Meta Description"
                    placeholder="Meta description"
                    disabled={isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-3 justify-end">
            <div className="p-4">
              <Button
                type="submit"
                loading={isCreatingSeo || isLoadingRecord || isUpdatingRecord}
              >
                {!isLoadingRecord && Object.keys(record || {}).length < 1
                  ? 'Create'
                  : 'Update'}
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </>
  );
};
