import { useUser } from "GlobalStore";

const UrlPath = new Map<string, string>()
  .set("read-user", "/app/Settings/users")
  .set("read-role", "/app/Settings/access-control/roles")
  .set("read-permission", "/app/Settings/access-control/roles");

export const useNextPath = () => {
  const user = useUser();

  const permission = user?.roles
    ?.map((role) =>
      role.permissions?.find((perm) => UrlPath.get(perm.slug) !== undefined)
    )
    .find((perm) => perm !== undefined);

  return UrlPath.get(permission?.slug as string) || "/app/dashboard";
};
