
export const optionsForTransferTypes = [
  { label: 'One Way', value: 'ONEWAY' },
  { label: 'Return (New Ride)', value: 'RETURN' },
];

export const BookingStatuses = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'In Progress',
    value: 'INPROGRESS',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },
]