import { Button } from 'components/Button';
import { FormikInput } from 'components/Input';
import { Logo } from 'components/Logo';
import { useFormik } from 'formik';
import { useCallback, useRef } from 'react';
import { Form } from 'components/Form';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from 'lib/api/user';
import { Alert } from 'components/Alert';
import { ResetPasswordDto } from 'lib/api/typings';
import { ApiErrorAlert } from 'components/ApiErrorAlert';
import ReCAPTCHA from 'react-google-recaptcha';

export const ForgotPassword = () => {
  const captchaRef = useRef<any>(null);

  const {
    mutateAsync: resetMutate,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useMutation(resetPassword);

  const onSubmit = useCallback(
    (values: ResetPasswordDto) => {
      let token = null;
      if (captchaRef && captchaRef.current) {
        token = captchaRef.current.getValue();
        captchaRef?.current?.reset();
      }

      if (!token) return;

      resetMutate(values);
    },
    [resetMutate],
  );

  const formik = useFormik<ResetPasswordDto>({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required('Please enter your email address'),
    }),
    onSubmit,
  });

  return (
    <div className="flex justify-center h-screen flex-1 items-center">
      <Form formik={formik}>
        <div className="flex gap-4 flex-col p-8 rounded-lg bg-white border w-96">
          <Logo />
          {isError && !isSuccess ? (
            <ApiErrorAlert error={error} />
          ) : (
            isSuccess && (
              <Alert
                type="success"
                title="Success"
                description="Please follow the steps sent to your registered email."
              />
            )
          )}

          <FormikInput
            placeholder="youremail@example.com"
            name="email"
            type="email"
            label="Email"
            disabled={isLoading}
          />

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            ref={captchaRef}
            className="mb-4"
          />

          <Button loading={isLoading} type="submit">
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};
