import capitalize from "lodash/capitalize";
import React from "react";
import cl from "classnames";
import {  FiAlertOctagon, FiAlertTriangle, FiCheckCircle } from "react-icons/fi";

type AlertType = "error" | "success" | "warning";

export interface AlertProps {
  title?: string;
  type?: AlertType;
  description?: string;
}

const TYPE_TO_ICON_MAP: Record<AlertType, React.ReactNode> = {
  error: <FiAlertOctagon />,
  success: <FiCheckCircle />,
  warning: <FiAlertTriangle />,
};

export const Alert = ({ type = "error", title, description }: AlertProps) => {
  return (
    <div
      className={cl(
        "flex items-center justify-between p-3  rounded-lg border",
        type === "error" && "bg-red-100 border-red-500",
        type === "success" && "bg-green-100 border-green-500",
        type === "warning" && "bg-amber-100 border-amber-500"
      )}
    >
      <div className="flex items-center gap-2">
        <div
          className={cl(
            " rounded-full p-2 text-xl text-white ",
            type === "error" && "bg-red-500 ",
            type === "success" && "bg-green-500 ",
            type === "warning" && "bg-amber-500 "
          )}
        >
          {TYPE_TO_ICON_MAP[type] || <FiAlertOctagon />}
        </div>
        <div>
          <h3 className="font-medium">{title || capitalize(type)}</h3>
          {description && (
            <p className="text-sm text-gray-500">{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};
