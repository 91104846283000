import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

export const Menu = ({
  modal = false,
  ...props
}: DropdownMenu.DropdownMenuProps) => {
  return <DropdownMenu.Root modal={modal} {...props} />;
};

const Trigger = ({ ...props }: DropdownMenu.DropdownMenuTriggerProps) => (
  <DropdownMenu.Trigger
    {...props}
  />
);

const Content = (props: DropdownMenu.DropdownMenuContentProps) => (
  <DropdownMenu.Portal>
    <DropdownMenu.Content
      className="bg-white z-10 border [--radix-dropdown-menu-content-transform-origin:start] shadow p-2 w-48 rounded-lg animate-appear"
      {...props}
      align="end"
    />
  </DropdownMenu.Portal>
);

const Item = ({
  children,
  icon,
  ...props
}: DropdownMenu.DropdownMenuItemProps & { icon?: React.ReactNode }) => (
  <DropdownMenu.Item
    className="hover:bg-gray-100 active:bg-gray-200 p-2 outline-none cursor-pointer rounded-lg"
    {...props}
  >
    <div className="flex items-center gap-1.5">
      {icon}
      {children}
    </div>
  </DropdownMenu.Item>
);

const Separator = (props: DropdownMenu.DropdownMenuSeparatorProps) => (
  <DropdownMenu.Separator className="my-2 h-px bg-gray-300" {...props} />
);

Menu.Trigger = Trigger;
Menu.Content = Content;
Menu.Item = Item;
Menu.Separator = Separator;
