import { useEffect, useMemo, useState } from "react";
import { debounce } from "debounce";

export const useDebounce = () => {
  const [filter, setFilter] = useState<string>("");
  const [text, setText] = useState<string>("");

  const debounced = useMemo(() => debounce(setFilter, 300), []);

  useEffect(() => {
    debounced(text);
  }, [text, debounced]);

  return {
    filter,
    setFilter,
    text,
    setText,
  };
};
