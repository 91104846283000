import { Header } from './Header';

export const AppLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div
      className="flex flex-col min-h-screen bg-white"
      style={{ backgroundImage: 'url(/logoBack.png)' }}
    >
      <Header />
      <main className="flex-1 max-w-7xl px-2 mx-auto w-full pb-10">
        {children}
      </main>
    </div>
  );
};
