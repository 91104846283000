import * as yup from "yup";

export const permissionFormConfig = {
  validationSchema: yup.object().shape({
    name: yup.string().trim().required("Please enter permission name"),
    slug: yup.string(),
  }),
  initialValues: {
    name: "",
    slug: "",
  },
};
