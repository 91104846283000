import { Fragment } from "react";
import { AiFillHome,  } from "react-icons/ai";
import { Link } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

export const Breadcrumbs = () => {
  const routes = [
    {
      path: "/app",
      breadcrumb: "Home",
    },
  ];
  const breadcrumbs = useReactRouterBreadcrumbs(routes, {
    excludePaths: ["/"],
  });

  return (
    <>
      <div className=" max-w-7xl  mx-auto w-full  ">
        <h2 className="flex items-center gap-1 text-sm py-2 px-6  text-gray-500 bg-slate-50   rounded-b-2xl">
          <AiFillHome className="mb-[2px]" />
          {breadcrumbs.map(({ match, breadcrumb }, i) => (
            <Link to={match.pathname} key={match.pathname}>
              {breadcrumb}
              {i + 1 < breadcrumbs.length && " / "}
            </Link>

          ))}
        </h2>
      </div>
    </>
  );
};
