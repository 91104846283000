import { useCallback } from 'react';
import { ApiClient } from './client';
import { useQuery } from '@tanstack/react-query';
import {
  BookingsControllerDeleteResponse,
  BookingsControllerFindAllParameters,
  BookingsControllerFindAllResponse,
  BookingsControllerFindByIdResponse,
  BookingsControllerUpdateParameters,
  BookingsControllerUpdateResponse,
  UpdateBookingDto,
} from './typings';

export const getBooking = async (id: string) => {
  return ApiClient.get<BookingsControllerFindByIdResponse<200>>(
    `/bookings/find/${id}`,
  ).then((res) => res.data?.data);
};

const fetchAllBookingsWithParams = async (
  params: BookingsControllerFindAllParameters,
) => {
  return ApiClient.get<BookingsControllerFindAllResponse<200>>(
    '/bookings/find',
    {
      params: {
        filter: JSON.stringify(params.filter),
        sort: JSON.stringify(params.sort),
        pageSize: params.pageSize,
        pageNumber: params.pageNumber,
      },
    },
  ).then((res) => res.data.data);
};

export const useSearchBookings = (
  payload: BookingsControllerFindAllParameters,
) => {
  const search = useCallback(() => {
    return fetchAllBookingsWithParams(payload);
  }, [payload]);

  return useQuery(['bookings', payload], search);
};

export const updateBookingRecord = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateBookingDto;
}) => {
  return ApiClient.patch<BookingsControllerUpdateResponse<200>>(
    `/bookings/update/${id}`,
    data,
  ).then((res) => res.data?.data);
};

export const deleteBooking = async (id: string) => {
  return ApiClient.delete<BookingsControllerDeleteResponse<200>>(
    `/bookings/remove/${id}`,
  ).then((res) => res.data.data);
};
