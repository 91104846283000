import {
  ColumnDef,
  createColumnHelper,
  useReactTable,
} from '@tanstack/react-table';
import { Card } from 'components/Card';
import { BookingResponseDto } from 'lib/api/typings';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Table } from 'components/Table';
import { Pagination } from 'components/Pagination';
import { Tag } from 'components/Tag';
import { useSkeletonTable } from 'hooks/createSkeletonTable';
import { NoRecordFound } from 'components/NoRecordFound';
import { Input } from 'components/Input';
import { useDebounce } from 'hooks/useDebounce';
import { useSearchBookings } from 'lib/api/bookings';

const columnHelper = createColumnHelper<BookingResponseDto>();

const columns = [
  columnHelper.accessor('users.firstName', {
    header: 'Name',
    cell: (val) => {
      const user = val.row.original?.users;
      const name = `${user?.firstName || '-'} ${user?.lastName}`;
      return name.length !== 0 ? name : '-';
    },
  }),
  columnHelper.accessor('users.email', {
    header: 'Email',
    cell: (val) => val.getValue() || '-',
  }),
  columnHelper.accessor('users.phoneNo', {
    header: 'Phone No',
    cell: (val) => val.getValue() || '-',
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (val) => {
      switch (val.getValue()) {
        case 'PENDING':
          return <Tag variant={'simple'}>{val.getValue()}</Tag>;
        case 'COMPLETED':
          return <Tag variant="success">{val.getValue()}</Tag>;
        case 'CANCELLED':
          return <Tag variant={'danger'}>{val.getValue()}</Tag>;
        case 'INPROGRESS':
          return <Tag variant={'info'}>{val.getValue()}</Tag>;
      }
    },
  }),
  columnHelper.accessor('from', {
    header: 'Pickup From',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('to', {
    header: 'Drop Off',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('flightNo', {
    header: 'Flight No',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('boosterSeat', {
    header: 'Booster Seat',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('babySeats', {
    header: 'Baby Seat',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('dogs', {
    header: 'Dogs',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('isMeetGreet', {
    header: 'Meet & Greet',
    cell: (val) =>
      val.getValue() ? (
        <Tag variant={'success'}>Yes</Tag>
      ) : (
        <Tag variant={'simple'}>No</Tag>
      ),
  }),
  columnHelper.accessor('passengers', {
    header: 'Passengers',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('luggage', {
    header: 'Luggage',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('distance', {
    header: 'Distance',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('pickupDate', {
    header: 'Pickup Date',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('returnDate', {
    header: 'Return Date',
    cell: (val) => val.getValue() || '-',
  }),
  columnHelper.accessor('prices.subtotal', {
    header: 'Subtotal',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('prices.grandTotal', {
    header: 'Grand Total',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('vehicles.rate', {
    header: 'Vehicle Rate',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('vehicles.class', {
    header: 'Vehicle Class',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('vehicles.company', {
    header: 'Vehicle Company',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('unit', {
    header: 'Unit',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: (val) => val.getValue(),
  }),
];

export const BookingListing = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();
  const { setText, filter } = useDebounce();

  const { skeletonColumns, skeletonRows } = useSkeletonTable(
    columns as ColumnDef<BookingResponseDto, any>[],
  );

  const onChangePageSize = useCallback(
    (size: number | string) => {
      setPageSize(size as number);
    },
    [setPageSize],
  );

  const { data, isLoading } = useSearchBookings({
    pageNumber: currentPageNumber,
    pageSize: pageSize,
    sort: {
      createdAt: 'desc',
    },
    filter: {
      ...(filter && {
        OR: [
          {
            from: filter,
          },
          {
            to: filter,
          },
        ],
      }),
    },
  });

  const rows = useMemo(
    () => (isLoading ? skeletonRows : data?.result || []),
    [data?.result, isLoading, skeletonRows],
  );

  const handleRowClick = useCallback(
    (row: BookingResponseDto) => {
      navigate(row.id.toString());
    },
    [navigate],
  );

  const table = useReactTable({
    columns: isLoading ? skeletonColumns : columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  let content: React.ReactNode;

  if (isLoading || rows.length > 0) {
    content = (
      <>
        <Table table={table} onRowClick={handleRowClick} />

        <Pagination
          currentPage={currentPageNumber}
          onPageChange={setCurrentPageNumber}
          pageCount={data?.pageCount || 0}
          total={data?.totalRecords || 0}
          pageSize={pageSize}
          onSizeChange={onChangePageSize}
        />
      </>
    );
  } else {
    content = (
      <div className="bg-gray-50 border">
        <NoRecordFound
          title="No records found."
          description="There are no bookings in our records."
        />
      </div>
    );
  }

  return (
    <Card>
      <div className="flex px-3 py-3 items-center justify-between">
        <h4 className="font-bold text-xl">Bookings</h4>
        <div className="flex gap-3">
          <Input
            placeholder="Search..."
            name="filter"
            onChange={(e: any) => setText(e.target.value)}
          />
        </div>
      </div>

      {content}
    </Card>
  );
};
