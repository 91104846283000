import { useQuery } from "@tanstack/react-query";
import { ApiClient } from "./client";
import {
  CreateRoleDto,
  RolesControllerCreateResponse,
  RolesControllerFindAllResponse,
  RolesControllerFindOneResponse,
  RolesControllerRemoveResponse,
  RolesControllerSearchParameters,
  RolesControllerSearchResponse,
  RolesControllerUpdateResponse,
  UpdateRoleDto,
} from "./typings";
import { useCallback } from "react";

export const getRoles = async () => {
  return await ApiClient.get<RolesControllerFindAllResponse<200>>(
    "/role/find",
  ).then((res) => res.data.data?.result);
};

export const getRoleById = (id: number) => {
  return ApiClient.get<RolesControllerFindOneResponse<200>>(`/role/find/${id}`).then(
    (res) => res.data.data!
  );
};

const getRolesWithPagination = async (
  params: RolesControllerSearchParameters
) => {
  return ApiClient.get<RolesControllerSearchResponse<200>>("/role/find", {
    params: {
      filter: JSON.stringify(params.filter),
      sort: JSON.stringify(params.sort),
      pageSize: params.pageSize,
      pageNumber: params.pageNumber,
    },
  }).then((res) => res.data.data);
};

export const useRolesQuery = (params: RolesControllerSearchParameters) => {
  const searchRolesWithParams = useCallback(() => {
    return getRolesWithPagination(params);
  }, [params]);

  return useQuery({
    queryFn: searchRolesWithParams,
    queryKey: ["role", params],
  });
};

export const createRole = async (data: CreateRoleDto) => {
  return await ApiClient.post<RolesControllerCreateResponse<201>>(
    "/role/create",
    data
  ).then((res) => res.data.data);
};

export const deleteRole = async (id: number) => {
  return await ApiClient.delete<RolesControllerRemoveResponse<200>>(
    `/role/remove/${id}`
  ).then((res) => res.data.data);
};

export const updateRole = async ({
  id,
  data,
}: {
  id: number;
  data: UpdateRoleDto;
}) => {
  return await ApiClient.patch<RolesControllerUpdateResponse<200>>(
    `/role/update/${id}`,
    data
  ).then((res) => res.data.data);
};
