import { Tag, TagProps } from "./Tag";

interface TagListProps {
  limit?: number;
  tags?: string[];
  tagProps?: TagProps;
  emptyTagsMessage?: string;
}

export const TagList = ({
  tags = [],
  limit = 5,
  emptyTagsMessage,
  tagProps,
}: TagListProps) => {
  const limitedTagList = tags.length > limit ? tags.slice(0, limit) : tags;

  return (
    <div className="flex flex-wrap gap-2">
      {limitedTagList.length < 1 && (
        <p className="text-sm text-gray-700">{emptyTagsMessage}</p>
      )}
      {limitedTagList.map((tag) => (
        <Tag key={tag} {...tagProps}>
          {tag}
        </Tag>
      ))}
      {limitedTagList.length < tags.length && (
        <Tag {...tagProps}>+ {tags.length - limitedTagList.length} more</Tag>
      )}
    </div>
  );
};
