import { Guard } from 'components/Guard';
import { Loader } from 'components/Loader';
import { OpenModal } from 'components/OpenModal';
import { SettingsLayout } from 'pages/app/Settings/SettingsLayout';
import { SignIn } from 'pages/auth/SignIn';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import { UsersListing } from 'pages/app/Settings/UsersAndResources/UsersListing';
import { NewUser } from 'pages/app/Settings/UsersAndResources/NewUser';
import NiceModal from '@ebay/nice-modal-react';
import { PermissionListing } from 'pages/app/Settings/Permissions/PermissionListing';
import { UpdatePermission } from 'pages/app/Settings/Permissions/UpdatePermission';
import { NewPermission } from 'pages/app/Settings/Permissions/NewPermission';
import { UpdateUser } from 'pages/app/Settings/UsersAndResources/UpdateUser';
import { NewRoles } from 'pages/app/Settings/Roles/NewRoles';
import { UpdateRoles } from 'pages/app/Settings/Roles/UpdateRoles';
import { RolesListing } from 'pages/app/Settings/Roles/RolesListing';
import { DashboardListing } from 'pages/app/Dashboard/DashboardListing';
import { ForgotPassword } from 'pages/auth/ForgotPassword';
import { ResetPassword } from 'pages/auth/ResetPassword';
import { AccountVerification } from 'pages/auth/AccountVerification';
import { usePermission } from 'hooks/useHasPermission';
import { PriceRuleListing } from 'pages/app/Settings/PriceRules/PriceRuleListing';
import { VehiclesListing } from 'pages/app/Settings/vehicles/VehiclesListing';
import { NewVehicle } from 'pages/app/Settings/vehicles/NewVehicle';
import { UpdateVehicle } from 'pages/app/Settings/vehicles/UpdateVehicle';
import { BookingListing } from 'pages/app/Settings/Bookings/BookingListing';
import { UpdateBooking } from 'pages/app/Settings/Bookings/UpdateBooking';
import { UpdateCreateSeo } from 'pages/app/Settings/Seo/UpdateCreateSeo';

export const Router = () => {
  const {
    hasRolePermission,
    hasPermissionAccess,
    hasACLPermission,
    showSettings,
    nextPath,
    hasReadPermission,
    hasBookingPermission,
  } = usePermission();

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <NiceModal.Provider>
          <Outlet />
        </NiceModal.Provider>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="/app" />,
        },
        {
          path: '/auth',
          element: <Guard guard="unauthenticated" />,
          children: [
            {
              path: 'sign-in',
              element: <SignIn />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'reset-password/:token',
              element: <ResetPassword />,
            },
            {
              path: 'verify/:token',
              element: <AccountVerification />,
            },
          ],
        },
        {
          path: '/app',
          element: <Guard guard="authenticated" />,
          children: [
            {
              index: true,
              element: <Navigate to="/app/settings" />,
            },
            // {
            //   index: true,
            //   element: <Navigate to="/app/dashboard" />,
            // },
            {
              path: 'dashboard',
              element: <DashboardListing />,
            },
            ...(!showSettings
              ? [
                  {
                    path: 'settings',
                    element: <SettingsLayout />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to={nextPath} />,
                      },
                      hasReadPermission && {
                        path: 'users',
                        element: <UsersListing />,
                        children: [
                          {
                            path: 'new',
                            element: <OpenModal modal={NewUser} />,
                          },
                          {
                            path: ':id',
                            element: <OpenModal modal={UpdateUser} />,
                          },
                        ],
                      },
                      {
                        path: 'price-rule',
                        element: <PriceRuleListing />,
                      },
                      {
                        path: 'vehicles',
                        element: <VehiclesListing />,
                        children: [
                          {
                            path: 'new',
                            element: <OpenModal modal={NewVehicle} />,
                          },
                          {
                            path: ':id',
                            element: <OpenModal modal={UpdateVehicle} />,
                          },
                        ],
                      },
                      hasACLPermission && {
                        path: 'access-control',
                        children: [
                          {
                            index: true,
                            element: <Navigate to="roles" />,
                          },
                          hasRolePermission && {
                            path: 'roles',
                            element: <RolesListing />,
                            children: [
                              {
                                path: 'new',
                                element: <OpenModal modal={NewRoles} />,
                              },
                              {
                                path: ':id',
                                element: <OpenModal modal={UpdateRoles} />,
                              },
                            ],
                          },
                          hasPermissionAccess && {
                            path: 'permissions',
                            element: <PermissionListing />,
                            children: [
                              {
                                path: 'new',
                                element: <OpenModal modal={NewPermission} />,
                              },
                              {
                                path: ':id',
                                element: <OpenModal modal={UpdatePermission} />,
                              },
                            ],
                          },
                        ],
                      },
                      hasBookingPermission && {
                        path: 'bookings',
                        element: <BookingListing />,
                      },
                      hasBookingPermission && {
                        path: 'bookings/:id',
                        element: <UpdateBooking />,
                      },
                      {
                        path: 'search-engine-optimization',
                        element: <UpdateCreateSeo />,
                      },
                    ],
                  },
                ]
              : []),
          ],
        },
        {
          path: '*',
          element: (
            <div className="flex justify-center mt-60">404 Not Found.</div>
          ),
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} fallbackElement={<Loader size="big" />} />
  );
};
