import classNames from "classnames";
import { useCallback } from "react";
import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight,
} from "react-icons/fi";
import { Button } from "./Button";
import { Input } from "./Input";
import { Listbox } from "./Listbox";

export interface PaginationProps {
  total: number;
  pageCount: number;
  pageSize: number;
  className?: string;
  currentPage: number;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number | string) => void;
}

export const Pagination = (props: PaginationProps) => {
  const start = Math.ceil((props.currentPage - 1) * props.pageSize) + 1;

  const options = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "25",
      value: 25,
    },
    {
      label: "50",
      value: 50,
    },
  ];

  const handleOnInputChange = useCallback(
    (e: any) => {
      const inputValue = e.currentTarget.valueAsNumber;
      if (inputValue <= props.pageCount && inputValue >= 1) {
        return props.onPageChange(e.currentTarget.valueAsNumber);
      }
    },
    [props]
  );

  return (
    <div
      className={classNames(
        "p-3 flex items-center justify-between border border-gray-200",
        props.className
      )}
    >
      <span>
        Showing <b>{props.pageCount > 1 ? start : props.total}</b>{" "}
        {props.pageCount > 1 && (
          <>
            to <b>{start + props.pageSize - 1}</b> of <b>{props.total}</b>
          </>
        )}{" "}
        result(s)
      </span>

      <div className="flex gap-3 ">
        <Listbox
          name="pageSize"
          options={options}
          changePageSize={props.onSizeChange}
          value={props.pageSize}
        />

        <Button
          icon={<FiChevronsLeft />}
          onClick={() => props.onPageChange(1)}
          variant="secondary"
          disabled={props.currentPage === 1}
          className="hover:bg-primary hover:text-white hover:border-transparent"
        />
        <Button
          icon={<FiChevronLeft />}
          onClick={() => props.onPageChange(props.currentPage - 1)}
          variant="secondary"
          className="hover:bg-primary hover:text-white hover:border-transparent"
          disabled={props.currentPage < 2}
        />
        <Input
          className="w-12 text-center appearance-none"
          value={props.currentPage}
          type="number"
          min="1"
          max={props.pageCount}
          onChange={handleOnInputChange}
          
        />
        <Button
          icon={<FiChevronRight />}
          onClick={() => props.onPageChange(props.currentPage + 1)}
          variant="secondary"
          disabled={props.currentPage === props.pageCount}
          className="hover:bg-primary hover:text-white hover:border-transparent"
        />
        <Button
          icon={<FiChevronsRight />}
          onClick={() => props.onPageChange(props.pageCount)}
          variant="secondary"
          disabled={props.currentPage === props.pageCount}
          className="hover:bg-primary hover:text-white hover:border-transparent"
        />
      </div>
    </div>
  );
};
