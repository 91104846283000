import { ApiClient } from './client';
import {
  CreatePriceRuleDto,
  PriceruleControllerCreateResponse,
  PriceruleControllerFindAllResponse,
  PriceruleControllerUpdateResponse,
  UpdatePriceRuleDto,
} from './typings';

export const createPriceRuleMutation = async (data: CreatePriceRuleDto) => {
  return await ApiClient.post<PriceruleControllerCreateResponse<201>>(
    '/price-rule/create',
    data,
  ).then((res) => res.data.data);
};

export const findPriceRules = async () => {
  return await ApiClient.get<PriceruleControllerFindAllResponse<200>>(
    '/price-rule/find',
  ).then((res) => res.data?.data);
};

export const updatePriceRuleMutation = async ({
  id,
  payload,
}: {
  id: string;
  payload: UpdatePriceRuleDto;
}) => {
  console.log(id, payload);

  return ApiClient.patch<PriceruleControllerUpdateResponse<200>>(
    `/price-rule/update/${id}`,
    payload,
  ).then((res) => res.data?.data);
};
