import { useQuery } from "@tanstack/react-query";
import { AsyncCombobox } from "components/Combobox";
import { Tag } from "components/Tag";
import { useFormikContext } from "formik";
import { useFieldArray } from "hooks/useFieldArray";
import { getRoles } from "lib/api/roles";
import { CreateUserDto, RoleResponseDto } from "lib/api/typings";

export const RoleSelector = <T extends CreateUserDto>({
  isDisabled = false,
}: {
  isDisabled: boolean;
}) => {
  const form = useFormikContext<T>();
  const { push, remove } = useFieldArray<string>("roles");
  const roleQuery = useQuery(["role"], getRoles, {
    select(data) {
      return (data || []).filter(
        (role: RoleResponseDto) => !form.values.roles?.includes(role.slug)
      );
    },
  });

  const { data: roles } = useQuery(["role"], getRoles);

  return (
    <>
      <div className="mt-2 grid grid-cols-2 gap-4">
        <AsyncCombobox
          inputProps={{ label: "Roles" }}
          query={roleQuery}
          labelAccessor={(val) => `${val.name}`}
          valueAccessor={(val) => val.slug}
          name="roles"
          placeholder="Select Role..."
          onChange={(val) => push(val)}
          disabled={isDisabled}
        />
      </div>

      <div className="pt-3 space-x-2 space-y-2">
        {form.values.roles?.map((ele: string, index: number) => {
          const role = roles?.find((role: RoleResponseDto) => role.slug === ele);

          return (
            <Tag
              key={index}
              hasRemoveBtn
              onDelete={() => {
                remove(index);
              }}
            >
              {role?.name || ele}
            </Tag>
          );
        })}
      </div>
    </>
  );
};
