import { FormikInput } from "components/Input";
import { FormikToggle } from "components/Toggle";
import { FiEye } from "react-icons/fi";
import { RoleSelector } from "./RoleSelector";
import { useState } from "react";

export const UserForm = ({
  disableRoles = false,
  hidePasswordField = false,
  isDisabled = false,
}: {
  disableRoles?: boolean;
  hidePasswordField?: boolean;
  isDisabled: boolean;
}) => {
  const [type, setType] = useState<string>("password");

  const handlePasswordClick = () => {
    setType((prev) => (prev === "password" ? "text" : "password"));
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <FormikInput
          name="firstName"
          label="First Name"
          placeholder="John"
          disabled={isDisabled}
        />
        <FormikInput
          name="lastName"
          label="Last Name"
          placeholder="Wick"
          disabled={isDisabled}
        />
        <FormikInput
          name="email"
          label="Email"
          placeholder="johnwick@nxb.com.pk"
          disabled={isDisabled}
        />
        <FormikInput
          name="phoneNo"
          type="tel"
          label="Mobile No"
          placeholder="e.g. +441632960345"
          disabled={isDisabled}
        />
        {!hidePasswordField && (
          <FormikInput
            name="password"
            type={type}
            value={Math.random().toString(36).slice(-8) + '!T4'}
            label="Password"
            disabled={true}
            suffixIcon={<FiEye onClick={handlePasswordClick} />}
          />
        )}
      </div>

      <div className="pt-4">
        <div className="flex flex-col-2 mt-2 gap-3">
          <FormikToggle name="isActive" disabled={isDisabled}>
            Active
          </FormikToggle>
          {/* <FormikToggle name="isVerified" disabled={isDisabled}>
            Verified
          </FormikToggle> */}
        </div>
        <hr className="mt-4" />
        <RoleSelector isDisabled={disableRoles} />
      </div>
    </>
  );
};
