import { Modal, useModal, useModalOnClose } from "components/Modal";
import { useEffect } from "react";
import { Form } from "components/Form";
import { Button } from "components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPermission } from "lib/api/permissions";
import { CreatePermissionDto } from "lib/api/typings";
import { ApiErrorAlert } from "components/ApiErrorAlert";
import { PermissionForm } from "./PermissionForm";
import { useFormik } from "formik";
import { permissionFormConfig } from "./PermissionConfig";

export const NewPermission = Modal.create(
  () => {
    const { close } = useModal();
    const queryClient = useQueryClient();
    const {
      mutateAsync: create,
      isLoading: isCreatingPermission,
      error,
    } = useMutation(createPermission, {
      onSuccess: () => queryClient.invalidateQueries(["permission"]),
    });

    const formik = useFormik<CreatePermissionDto>({
      ...permissionFormConfig,
      onSubmit: async (values) => {
        await create(values);
        close(true);
      },
    });

    useModalOnClose(async () => {
      if (isCreatingPermission) return false;

      if (formik.dirty) {
        return Modal.confirm();
      }

      return true;
    });

    const { setFieldValue } = formik;

    useEffect(() => {
      setFieldValue(
        "slug",
        formik.values.name.replace(/\s/g, "-").toLowerCase()
      );
    }, [setFieldValue, formik.values.name]);

    return (
      <Form formik={formik}>
        <Modal.Title>Create new Permission</Modal.Title>

        <div className="p-4">
          <ApiErrorAlert error={error} />
          <PermissionForm isDisabled={isCreatingPermission} />
        </div>
        <Modal.Actions>
          <Modal.CloseButton disabled={isCreatingPermission} />
          <Button type="submit" loading={isCreatingPermission}>
            Create
          </Button>
        </Modal.Actions>
      </Form>
    );
  },
  { size: "lg" }
);
