import cl from 'classnames';
import { NavLink } from 'react-router-dom';
import { useIsSuperUser } from 'GlobalStore';

const MainMenuLink = ({
  to,
  children,
}: {
  to: string;
  children?: React.ReactNode;
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => {
        return cl(
          'text-white-500 py-2 px-4 rounded-full active:scale-105 transition-transform',
          isActive && 'bg-primary hover:bg-primaryHover text-white'
        );
      }}
    >
      <li>{children}</li>
    </NavLink>
  );
};

export const MainMenu = () => {
  const superUser = useIsSuperUser();

  return (
    <nav className='flex items-center'>
      <ul className='flex items-center gap-4'>
        <MainMenuLink to='/app/dashboard'>Dashboard</MainMenuLink>
      </ul>
    </nav>
  );
};
