import cl from "classnames";
import React from "react";
import { FiX } from "react-icons/fi";

export type TagProps = React.PropsWithChildren<{
  variant?: "success" | "info" | "danger" | "warning" | "simple";
  hasRemoveBtn?: boolean;
  className?: string;
  onDelete?: () => void;
}>;

export const Tag = ({
  children,
  variant = "simple",
  hasRemoveBtn = false,
  className,
  onDelete,
}: TagProps) => {
  return (
    <span
      className={cl(
        "text-sm inline-flex gap-2 items-center  rounded-lg",
        variant === "success" && "bg-green-200 text-green-900",
        variant === "simple" && "bg-gray-200 text-gray-900",
        variant === "info" && "bg-sky-100 text-blue-500",
        variant === "danger" && "bg-rose-200 text-rose-900",
        variant === "warning" && "bg-amber-200 text-amber-900",
        className,
      )}
    >
      <div className="p-2">

      {children}
      </div>
      {hasRemoveBtn && (
        <button onClick={onDelete} className="border-l hover:bg-gray-500 hover:text-white rounded-r-lg h-full border-gray-400 p-2" type="button">
          <FiX
            className="cursor-pointer transition-opacity rounded-lg"     
          />
        </button>
      )}
    </span>
  );
};
