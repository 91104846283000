import { Link } from 'react-router-dom';
// import image from '../../public/logo.png';

export const Logo = () => (
  <Link to="/app">
    <h2 className="flex font-bold text-xl items-center">
      <img src="/logo.png" alt="Edinburgh Transfers" height={50} width={80} />
      <span className="font-normal">Edinburgh</span>
      <span className="text-logo">Transfers</span>
    </h2>
  </Link>
);
