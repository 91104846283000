import { ApiErrorAlert } from 'components/ApiErrorAlert';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { Loader } from 'components/Loader';
import { Modal, useModal, useModalOnClose } from 'components/Modal';
import { useUpdateEntity } from 'hooks/useUpdateEntity';
import { FiTrash } from 'react-icons/fi';
import { useUser } from 'GlobalStore';
import { useHasPermission } from 'hooks/useHasPermission';
import { VehiclesConfig } from './VehicleConfig';
import { VehicleForm } from './VehicleFormFields';
import { deleteVehicle, getVehicleById, updateVehicle } from 'lib/api/vehicles';
import { UpdateVehicleDto } from 'lib/api/typings';

export const UpdateVehicle = Modal.create(
  () => {
    const currentUser = useUser();
    const { close } = useModal();

    const {
      isUpdatingRecord,
      error,
      formik,
      handleDeleteButtonClk,
      isCloseButtonDisbaled,
      isDeleteButtonDisabled,
      isDeletingRecord,
      isFormDisabled,
      isLoadingRecord,
      isUpdateButtonDisabled,
      record,
    } = useUpdateEntity<UpdateVehicleDto>({
      formConfig: VehiclesConfig,
      entityName: 'vehicle',
      deleteApi: deleteVehicle,
      updateApi: updateVehicle,
      fetchPreviousData: async (id) => {
        const data = await getVehicleById(id);
        Object.assign(data, {
          ...data,
          documents: data.images?.split(',').map((file) => ({
            name: file,
          })),
          seats: `${data.seats}`,
          bags: `${data.bags}`,
        });

        return data;
      },
      onDelete: () => close(true),
      onUpdate: () => close(true),
    });

    const hasDeletePermission = useHasPermission('delete-vehicle');
    const hasUpdatePermission = useHasPermission('update-vehicle');

    const disableForLoggedIn =
      record && currentUser
        ? currentUser.id === record.id || isDeleteButtonDisabled
        : false;

    const isSuperAdminData = record?.isSuperAdmin;

    useModalOnClose(() => {
      if (isUpdatingRecord) return false;

      if (formik.dirty) return Modal.confirm({});

      return true;
    });

    return (
      <Form formik={formik}>
        <Modal.Title>
          <div className="flex gap-2 items-center">
            {isLoadingRecord && <Loader />}
            Update Vehicle
          </div>
        </Modal.Title>
        <div className="p-4">
          <ApiErrorAlert error={error} />

          <VehicleForm isDisabled={isFormDisabled} errors={formik.errors} />
        </div>
        <Modal.Actions>
          <Modal.CloseButton
            disabled={isCloseButtonDisbaled}
          ></Modal.CloseButton>
          <div className="flex gap-2 items-center">
            <Button
              type="button"
              variant="secondary"
              className="text-rose-500"
              icon={<FiTrash />}
              loading={isDeletingRecord}
              onClick={handleDeleteButtonClk}
              disabled={
                disableForLoggedIn || isSuperAdminData || !hasDeletePermission
              }
            >
              Delete
            </Button>
            <Button
              type="submit"
              disabled={isUpdateButtonDisabled || !hasUpdatePermission}
              loading={isUpdatingRecord}
            >
              Update
            </Button>
          </div>
        </Modal.Actions>
      </Form>
    );
  },
  { size: 'lg' },
);
