import { Button } from 'components/Button';
import { Tag } from 'components/Tag';
import { useFieldArray } from 'hooks/useFieldArray';
import { useRef } from 'react';
import { FiAlertOctagon, FiFilePlus } from 'react-icons/fi';

interface FileInterface {
  isDisabled: boolean;
  logRemovedFiles?: (id: number) => void;
  errors: { [key: string]: any };
  label: string;
}
export const FileSection = ({
  isDisabled,
  logRemovedFiles,
  errors,
  label,
}: FileInterface) => {
  //upon updating the field is images from backend
  const { push, remove, array } = useFieldArray<File>('documents');
  const fileFieldRef = useRef<HTMLInputElement>(null);

  const handleOnDelete = (file: any, index: number) => {
    if (file.id && logRemovedFiles) logRemovedFiles(file.id);

    remove(index);
  };

  return (
    <div className="flex flex-col">
      <span className="font-medium mb-1">{label}</span>
      <div>
        <input
          type="file"
          ref={fileFieldRef}
          className="hidden"
          name="documents"
          disabled={isDisabled}
          onChange={(e) => {
            if (e.currentTarget.files) {
              push(...Array.from(e.currentTarget.files));
              e.currentTarget.value = '';
            }
          }}
          accept="image/*"
          multiple
        />
        <Button
          type="button"
          variant="primary"
          onClick={() => fileFieldRef.current?.click()}
          icon={<FiFilePlus />}
        >
          Choose files
        </Button>
      </div>
      <p className="text-gray-500 text-sm mt-1">JPG or PNG (MAX 1MB).</p>
      {errors['documents'] && (
        <div className="flex text-xs items-center gap-1 mt-2 border-transparent text-rose-500">
          <FiAlertOctagon className="text-sm" />
          <span className="font-medium">{errors['documents']}</span>
        </div>
      )}
      <div className="pt-4 flex flex-wrap gap-2">
        {array?.map((file: File, index: number) => {
          return (
            <Tag
              key={index}
              hasRemoveBtn
              onDelete={() => handleOnDelete(file, index)}
            >
              {file.name}
            </Tag>
          );
        })}
      </div>
    </div>
  );
};
