import { ColumnDef } from "@tanstack/react-table";
import { noopObject } from "lib/noop";
import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

const createSkeletonColumns = <T, Y>(
  columns: ColumnDef<T, Y>[]
): ColumnDef<T, Y>[] => {
  return columns.map((column) => ({
    ...column,
    cell: () => <Skeleton />,
  }));
};

export const useSkeletonTable = <T, Y>(
  columns: ColumnDef<T, Y>[],
  size = 10
) => {
  return useMemo(
    () => ({
      skeletonRows: new Array(size).fill(() => noopObject),
      skeletonColumns: createSkeletonColumns(columns),
    }),
    [columns, size]
  );
};
