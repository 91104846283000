import React from "react";
import cl from "classnames";
import { Loader } from "./Loader";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "ghost" | "danger";
  icon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  loading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      className,
      icon,
      suffixIcon,
      children,
      loading = false,
      disabled = false,
      ...restProps
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={cl(
          variant === "primary" &&
            " bg-primary text-white  hover:bg-primaryHover border-none disabled:bg-gray-500 disabled:text-gray-200 disabled:border-gray-500",
          variant === "secondary" &&
            " hover:bg-gray-100 border-gray-200 bg-white disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-100",
          variant === "danger" &&
            "bg-rose-600 text-white ring-rose-600 hover:bg-rose-700 border-rose-600 hover:border-rose-700 disabled:bg-rose-900 disabled:text-gray-400 disabled:border-rose-900",
          variant === "ghost" &&
            "border-transparent  disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-100",
          "rounded-lg flex gap-1 ring-gray-900 justify-center	relative items-center border-2 transition-all ring-offset-1 py-2 focus:ring-2    active:scale-95 disabled:active:scale-100",
          children ? "px-4" : "px-3",
          className,
        )}
        disabled={loading || disabled}
        {...restProps}
      >
        {loading ? <Loader /> : icon}
        {children}
        {suffixIcon}
      </button>
    );
  }
);
