import { FormikProvider, useFormik, FormikValues } from "formik";
import React, { useMemo } from "react";

interface FormProps<T extends FormikValues> {
  formik: ReturnType<typeof useFormik<T>>;
  children?: React.ReactNode;
  hasFormElement?: boolean;
  className?: string;
}

export const Form = <T extends FormikValues>({
  formik,
  children,
  className,
  hasFormElement = true,
}: FormProps<T>) => {
  const content = useMemo(() => {
    return hasFormElement ? (
      <form className={className} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        {children}
      </form>
    ) : (
      <>{children}</>
    );
  }, [children, className, formik.handleReset, formik.handleSubmit, hasFormElement]);

  return <FormikProvider value={formik}>{content}</FormikProvider>;
};
