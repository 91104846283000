import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Pagination } from "components/Pagination";
import { usePermissionsQuery } from "lib/api/permissions";
import { useMemo, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Table } from "components/Table";
import { PermissionResponseDto } from "lib/api/typings";
import { formatDate } from "lib/dateformatter";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useSkeletonTable } from "hooks/createSkeletonTable";
import { useCallback } from "react";
import { NoRecordFound } from "components/NoRecordFound";

const columnHelper = createColumnHelper<PermissionResponseDto>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (val) => {
      return (
        <div className="flex flex-col">
          <b className="font-medium">{val.getValue()}</b>
          {val.row.original.slug}
        </div>
      );
    },
  }),
  // columnHelper.accessor("permissions", {
  //   header: "Permissions",
  //   cell: (val) => (
  //     <TagList limit={2} emptyTagsMessage="No permissions assigned" tags={val.getValue()?.map((permission) => permission.slug)} />
  //   ),
  // }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    cell: (val) => formatDate(val.getValue()),
  }),
];

export const PermissionListing = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [curPageSize, setCurPageSize] = useState<number>(10);
  const { skeletonColumns, skeletonRows } = useSkeletonTable(
    columns as ColumnDef<PermissionResponseDto, any>[]
  );

  const onChangePageSize = useCallback(
    (size: number | string) => {
      setCurPageSize(size as number);
    },
    [setCurPageSize]
  );

  const { data, isLoading } = usePermissionsQuery({
    pageNumber: currentPageNumber,
    pageSize: curPageSize,
    sort: {
      createdAt: "desc",
    },
  });

  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (row: PermissionResponseDto) => {
      navigate(row.id.toString());
    },
    [navigate]
  );

  const rows = useMemo(
    () => (isLoading ? skeletonRows : data?.result || []),
    [data?.result, isLoading, skeletonRows]
  );

  const table = useReactTable({
    columns: isLoading ? skeletonColumns : columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  let content: React.ReactNode;

  if (isLoading || rows.length > 0) {
    content = (
      <>
        <Table table={table} onRowClick={handleRowClick} />

        <Pagination
          currentPage={currentPageNumber}
          onPageChange={setCurrentPageNumber}
          pageCount={data?.pageCount || 0}
          total={data?.totalRecords || 0}
          pageSize={curPageSize}
          onSizeChange={onChangePageSize}
        />
      </>
    );
  } else {
    content = (
      <div className="bg-gray-50 border">
        <NoRecordFound
          title="No records found."
          description="There are no permissions in our records, please create one using above button."
        />
      </div>
    );
  }

  return (
    <>
      <Card>
        <div className="flex p-3 items-center justify-between">
          <h4 className="font-bold text-xl">Permissions</h4>
        </div>
        {content}
      </Card>

      <Outlet />
    </>
  );
};
