import { FormikInput } from 'components/Input';
import { FormikToggle } from 'components/Toggle';
import { FormikCombobox } from 'components/Combobox';
import { FileSection } from 'components/FileSection';

export const VehicleForm = ({
  isDisabled = false,
  errors,
}: {
  isDisabled: boolean;
  errors: { [key: string]: any };
}) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <FormikInput
          name="vin"
          label="VIN"
          placeholder="Vehicle Identification Number"
          disabled={isDisabled}
        />
        <FormikInput
          name="regNo"
          label="Reg No"
          placeholder="Registration Number"
          disabled={isDisabled}
        />
        <FormikInput
          name="rate"
          label="Rate"
          placeholder="Vehicle Rate"
          disabled={isDisabled}
        />
        <FormikInput
          name="engineNo"
          label="Engine No"
          placeholder="Engine Number"
          disabled={isDisabled}
        />
        <FormikInput
          name="modelYear"
          type="number"
          label="Model Year"
          placeholder="Model"
          disabled={isDisabled}
        />
        <FormikInput
          name="company"
          label="Company"
          placeholder="Make Company"
          disabled={isDisabled}
        />
        <FormikInput
          name="bags"
          label="Luggage Bags"
          placeholder="Bags this vehicle can carry"
          disabled={isDisabled}
        />
        <FormikCombobox
          inputProps={{ label: 'Class' }}
          name="class"
          placeholder="Select"
          options={[
            {
              label: 'Executive',
              value: 'EXECUTIVE',
            },
            {
              label: 'Saloon',
              value: 'SALOON',
            },
            {
              label: 'Estate',
              value: 'ESTATE',
            },
            {
              label: 'MPV B',
              value: 'MPVB',
            },
            {
              label: 'Minibus A',
              value: 'MINIBUSA',
            },
          ]}
          disabled={isDisabled}
        />

        <FormikCombobox
          inputProps={{ label: 'Seats' }}
          name="seats"
          placeholder="Select"
          options={[
            {
              label: '3',
              value: '3',
            },
            {
              label: '4',
              value: '4',
            },
            {
              label: '6',
              value: '6',
            },
            {
              label: '8',
              value: '8',
            },
          ]}
          disabled={isDisabled}
        />

        <FileSection
          label="Vehicle Image"
          errors={errors}
          isDisabled={isDisabled}
        />
      </div>

      <FormikInput
        type="textarea"
        name="description"
        label="Description"
        placeholder="Some description about the vehicle"
        disabled={isDisabled}
      />

      <div className="flex flex-col-2 mt-2 gap-3">
        <FormikToggle name="isActive" disabled={isDisabled}>
          Active
        </FormikToggle>
      </div>
    </>
  );
};
