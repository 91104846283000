import { FormikInput } from "components/Input";
import { PermissionSelector } from "./PermissionSelector";

export const RolesForm = ({ isDisabled = false }: { isDisabled: boolean }) => {
  return (
    <>
      <div className=" grid grid-cols-2 gap-4">
        <FormikInput
          name="name"
          label="Role Name"
          placeholder="Editor"
          disabled={isDisabled}
        />

        <FormikInput
          name="slug"
          label="Slug"
          placeholder="editor"
          disabled={true}
        />
      </div>

      <PermissionSelector />
    </>
  );
};
