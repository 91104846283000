import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { ApiClient } from "./client";
import {
  CreatePermissionDto,
  PermissionsControllerCreateResponse,
  PermissionsControllerFindAllResponse,
  PermissionsControllerFindOneResponse,
  PermissionsControllerRemoveResponse,
  PermissionsControllerSearchParameters,
  PermissionsControllerSearchResponse,
  PermissionsControllerUpdateResponse,
  UpdatePermissionDto,
} from "./typings";

export const getPermissions = async () => {
  return await ApiClient.get<PermissionsControllerFindAllResponse<200>>(
    "/permission",
    {}
  ).then((res) => res.data.data);
};

export const getPermission = async (id: number) => {
  return await ApiClient.get<PermissionsControllerFindOneResponse<200>>(
    `/permission/find/${id}`,
  ).then((res) => res.data.data!);
};


export const updatePermission = async (params: {
  id: number;
  data: UpdatePermissionDto;
}) => {
  return await ApiClient.patch<PermissionsControllerUpdateResponse<200>>(
    `/permission/update/${params.id}`,
    params.data
  ).then((res) => res.data.data);
};

export const deletePermission = async (id: number) => {
  return await ApiClient.delete<PermissionsControllerRemoveResponse<200>>(
    `/permission/remove/${id}`
  ).then((res) => res.data.data);
};

export const createPermission = async (params: CreatePermissionDto) => {
  return await ApiClient.post<PermissionsControllerCreateResponse<201>>(
    "/permission/create",
    params
  ).then((res) => res.data.data);
};

export const getPermissionsWithParams = async (
  params: PermissionsControllerSearchParameters
) => {
  return ApiClient.get<PermissionsControllerSearchResponse<200>>(
    "/permission/find",
    {
      params: {
        filter: JSON.stringify(params.filter),
        sort: JSON.stringify(params.sort),
        pageSize: params.pageSize,
        pageNumber: params.pageNumber,
      },
    }
  ).then((res) => res.data.data);
};

export const usePermissionsQuery = (
  params: PermissionsControllerSearchParameters
) => {
  const queryFn = useCallback(() => {
    return getPermissionsWithParams(params);
  }, [params]);

  return useQuery({
    queryFn,
    queryKey: ["permission", params],
  });
};

export const useCreatePermissionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPermission,
    onSuccess: () => queryClient.invalidateQueries(["permission"]),
  });
};
