import { useCallback } from 'react';
import { ApiClient } from './client';
import {
  CreateVehicleDto,
  UpdateVehicleDto,
  VehicleControllerCreateResponse,
  VehicleControllerDeleteResponse,
  VehicleControllerFindAllParameters,
  VehicleControllerFindAllResponse,
  VehicleControllerFindOneResponse,
  VehicleControllerUpdateResponse,
} from './typings';
import { useQuery } from '@tanstack/react-query';

const headers = {
  'Content-Type': 'multipart/form-data',
};
export const createVehicle = async (data: CreateVehicleDto) => {
  const formData = generateFormData(data);

  return await ApiClient.post<VehicleControllerCreateResponse<201>>(
    '/vehicle/create',
    formData,
    {
      headers,
    },
  ).then((res) => res.data.data);
};

const getVehiclesWithParams = async (
  params: VehicleControllerFindAllParameters,
) => {
  return ApiClient.get<VehicleControllerFindAllResponse<200>>('/vehicle/find', {
    params: {
      filter: JSON.stringify(params.filter),
      sort: JSON.stringify(params.sort),
      pageSize: params.pageSize,
      pageNumber: params.pageNumber,
    },
  }).then((res) => res.data.data);
};

export const useSearchVehicles = (
  params: VehicleControllerFindAllParameters,
) => {
  const search = useCallback(() => {
    return getVehiclesWithParams(params);
  }, [params]);

  return useQuery(['vehicle', params], search);
};

export const getVehicles = async () => {
  return ApiClient.get<VehicleControllerFindAllResponse<200>>(
    '/vehicle/find',
  ).then((response) => response.data.data);
};

export const getVehicleById = (id: string) => {
  return ApiClient.get<VehicleControllerFindOneResponse<200>>(
    `/vehicle/find/id/${id}`,
  ).then((res) => res.data.data!);
};

export const updateVehicle = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateVehicleDto;
}) => {
  const formData = generateFormData(data);

  return ApiClient.patch<VehicleControllerUpdateResponse<200>>(
    `/vehicle/update/${id}`,
    formData,
    {
      headers,
    },
  ).then((res) => res.data.data);
};

export const deleteVehicle = async (id: string) => {
  return ApiClient.delete<VehicleControllerDeleteResponse<200>>(
    `/vehicle/remove/${id}`,
  ).then((res) => res.data.data);
};

const generateFormData = (data: any) => {
  delete data.images;

  const formData = new FormData();

  for (const d in data) {
    if (d !== 'documents') {
      formData.append(d, data[d]);
    }
  }

  if (data.documents) {
    const files: any = data.documents;

    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }
  } else {
    formData.append('images', JSON.stringify([]));
  }

  return formData;
};
