import * as yup from 'yup';

type VehicleClass = 'EXECUTIVE' | 'ESTATE' | 'SALOON' | 'MPVB' | 'MINIBUSA';

const SUPPORTED_FORMAT = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/wps-office.docx',
];

const validateFileType = (file: any): boolean => {
  for (const obj in file) {
    if (file[obj].name !== undefined && file[obj].name !== 'item') {
      return (
        SUPPORTED_FORMAT.includes(file[obj].type) ||
        file[obj].type === undefined
      );
    }
  }
  return false;
};

const validateImageFileType = (file: any): Promise<boolean> | boolean => {
  const isJPEG = (buffer: Uint8Array): boolean =>
    buffer.length >= 2 && buffer[0] === 0xff && buffer[1] === 0xd8;

  const isPNG = (buffer: Uint8Array): boolean =>
    buffer.length >= 8 &&
    buffer[0] === 0x89 &&
    buffer[1] === 0x50 &&
    buffer[2] === 0x4e &&
    buffer[3] === 0x47 &&
    buffer[4] === 0x0d &&
    buffer[5] === 0x0a &&
    buffer[6] === 0x1a &&
    buffer[7] === 0x0a;

  for (const obj in file) {
    if (
      file[obj].name !== undefined &&
      file[obj].name !== 'item' &&
      file[obj] instanceof Blob
    ) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file[obj]);
      return new Promise<boolean>((resolve) => {
        reader.onloadend = () => {
          const buffer = new Uint8Array(reader.result as ArrayBuffer);
          resolve(isJPEG(buffer) || isPNG(buffer));
        };
      });
    } else {
      if (!(file[obj] instanceof Blob) && typeof file[obj].name === 'string') {
        return true;
      }
    }
  }

  return false;
};

export const VehiclesConfig = {
  initialValues: {
    vin: '',
    regNo: '',
    engineNo: '',
    modelYear: 2023,
    company: '',
    class: '' as VehicleClass,
    seats: 0,
    bags: 0,
    description: '',
    documents: [] as Array<string>,
    images: [] as Array<string>,
    isActive: false,
    rate: 0
  },
  validationSchema: yup.object().shape({
    vin: yup
      .string()
      .trim()
      .required('Please enter Vehicle Identification Number.'),
    regNo: yup.string().trim().required('Please enter registration Number.'),
    engineNo: yup.string().trim().required('Please enter Engine Number.'),
    modelYear: yup.number().required('Please enter model year.'),
    company: yup.string().trim().required('Please enter company name.'),
    class: yup
      .string()
      .oneOf(['EXECUTIVE', 'ESTATE', 'SALOON', 'MPVB', 'MINIBUSA'])
      .required('Please choose class'),
    seats: yup.number().required('Please choose vehicle seats.'),
    bags: yup.number().required('Please choose vehicle bags.'),
    rate: yup.number().required('Please enter rate for this vehicle.'),
    isActive: yup.boolean(),
    documents: yup
      .mixed()
      .test({
        message: 'Please upload at least one supporting documents',
        test: (file, context) => {
          return context.parent.documents.length > 0;
        },
      })
      .test({
        message: 'Invalid file, only [.PNG, .JPG] allowed',
        test: async (file, context) => {
          return await validateImageFileType(context.parent.documents);
        },
      }),
  }),
};
