import { useUser } from "GlobalStore";
import { AppLayout } from "layout/AppLayout";
import { InProgress } from "../InProgress";

export const DashboardListing = () => {
  const loggedInUser = useUser();

  const hasDashboardAccess = loggedInUser?.roles?.some((role) =>
    role.permissions?.some(
      (permission) => permission.slug === "dashboard-access"
    )
  );

  return (
    <>
      {hasDashboardAccess ? (
        <InProgress />
      ) : (
        <InProgress />
      )}
    </>
  );
};
