import NiceModal, {
  NiceModalHocProps,
  remove,
  useModal as useNiceModal,
} from '@ebay/nice-modal-react';
import {} from '@ebay/nice-modal-react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, {
  createRef,
  Fragment,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Button, ButtonProps } from './Button';

type ModalProps = {
  children?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

export type ConfirmModalProps = {
  confirmButtonText?: string;
  denyButtonText?: string;
  title?: string;
  description?: string;
  confirmButtonVariant?: ButtonProps['variant'];
  denyButtonVariant?: ButtonProps['variant'];
};

type ShouldCloseFunction = () => boolean | Promise<boolean>;

type ModalContextType = {
  shouldClose: RefObject<ShouldCloseFunction>;
  setShouldClose: (func: ShouldCloseFunction) => void;
};

const ModalContextDefaultValue = {
  shouldClose: createRef<ShouldCloseFunction>(),
  setShouldClose: () => ({}),
};

const ModalContext = React.createContext<ModalContextType>(
  ModalContextDefaultValue,
);

const ModalContextProvider = ({
  children,
}: React.PropsWithChildren<object>) => {
  const shouldClose = useRef<ShouldCloseFunction>(() => true);
  const setShouldClose = useCallback((func: ShouldCloseFunction) => {
    shouldClose.current = func;
  }, []);

  return (
    <ModalContext.Provider
      value={{
        shouldClose,
        setShouldClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalOnClose = (func: ShouldCloseFunction) => {
  const context = useContext(ModalContext);

  useEffect(() => {
    context.setShouldClose(func);
  }, [context, func]);

  return context.setShouldClose(func);
};

export const useModal = () => {
  const modalContext = useContext(ModalContext);

  if (!modalContext) {
    throw new Error('Use modal context inside a ModalContextProvider');
  }

  const modal = useNiceModal();

  const close = useCallback(
    async (force = false) => {
      if ((await modalContext.shouldClose.current?.()) || force) {
        return modal.hide();
      }
    },
    [modal, modalContext],
  );

  const resolveClose = useCallback(
    async (args?: unknown, force = false) => {
      if ((await modalContext.shouldClose.current?.()) || force) {
        modal.resolve(args);
        return modal.hide();
      }
    },
    [modal, modalContext],
  );

  const hide = useCallback(() => {
    return modal.hide();
  }, [modal]);

  return {
    ...modal,
    visible: modal.visible,
    close,
    resolveClose,
    hide,
    resolve: modal.resolve,
    reject: modal.reject,
  };
};

export const Modal = ({ children, size = 'md' }: ModalProps) => {
  const { visible, close, remove } = useModal();

  return (
    <Transition appear show={visible} afterLeave={remove} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full  items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'w-full transform rounded-lg bg-white text-left align-middle shadow transition-all',
                  size === 'sm' && 'max-w-md',
                  size === 'md' && 'max-w-lg',
                  size === 'lg' && 'max-w-xl',
                  size === 'xl' && 'max-w-3xl',
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Actions = ({ children }: { children: React.ReactNode }) => {
  return <div className="p-4 border-t flex justify-between">{children}</div>;
};

const CloseButton = (props: ButtonProps) => {
  const modal = useModal();

  return (
    <Button
      type="button"
      variant="secondary"
      onClick={() => modal.close()}
      {...props}
    >
      Close
    </Button>
  );
};

const ModalTitle = ({ children }: React.PropsWithChildren<object>) => {
  return (
    <Dialog.Title
      as="h3"
      className="text-lg p-4  border-b font-medium leading-6 text-gray-900"
    >
      {children}
    </Dialog.Title>
  );
};

Modal.create = <T extends object>(
  Comp: React.ComponentType<T>,
  modalProps: Omit<ModalProps, 'children'> = {},
  isMainComp = false,
) => {
  return (
    { componentProps, ...props }: NiceModalHocProps & { componentProps: T },
    context?: any,
  ) => {
    return (
      <ModalContextProvider>
        {isMainComp
          ? NiceModal.create(Comp)({ ...props, ...componentProps }, context)
          : NiceModal.create(Modal)(
              {
                ...modalProps,
                ...props,
                children: <Comp {...componentProps} />,
              },
              context,
            )}
      </ModalContextProvider>
    );
  };
};

const ConfirmModal = ({
  confirmButtonText = 'Confirm',
  confirmButtonVariant = 'primary',
  denyButtonText = 'Cancel',
  denyButtonVariant = 'secondary',
  title = 'Unsaved Changes',
  description = 'There are unsaved changes, are you sure you want to close?',
}: ConfirmModalProps) => {
  const modal = useModal();
  // const niceModalContext = useContext(NiceModal.NiceModalContext);
  // const ref = useRef<string>();

  const createHandler = useCallback(
    (resolveStatus: boolean) => {
      return () => {
        // if (ref.current) Modal.show(ref.current);
        return modal.resolveClose(resolveStatus);
      };
    },
    [modal],
  );

  // useEffect(() => {
  //   if (ref.current) return;
  //   const modals = Object.values(niceModalContext);

  //   if (modals.length > 0) {
  //     const id = modals[modals.length - 2].id;
  //     Modal.hide(id);
  //     ref.current = id;
  //   }
  // }, [niceModalContext]);

  return (
    <Modal>
      <ModalTitle>{title}</ModalTitle>

      <div className="p-4">{description}</div>

      <Modal.Actions>
        <Modal.CloseButton
          onClick={createHandler(false)}
          variant={denyButtonVariant}
        >
          {denyButtonText}
        </Modal.CloseButton>
        <Button onClick={createHandler(true)} variant={confirmButtonVariant}>
          {confirmButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

Modal.confirm = async (props?: ConfirmModalProps) => {
  return NiceModal.show<boolean>(Modal.create(ConfirmModal, {}, true), props);
};

Modal.Actions = Actions;
Modal.Title = ModalTitle;
Modal.CloseButton = CloseButton;
Modal.show = NiceModal.show;
Modal.hide = NiceModal.hide;
