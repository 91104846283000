import * as yup from "yup";

export const UserConfigForm = {
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    isActive: false,
    roles: [] as string[],
    password: Math.random().toString(36).slice(-8) + '!T4'
  },
  validationSchema: yup.object().shape({
    firstName: yup.string().trim().required("Please enter first name."),
    lastName: yup.string().trim().required("Please enter last name."),
    email: yup.string().trim().email().required("Please enter email."),
    isActive: yup.boolean().required(),
    roles: yup.array().not([0], "Please choose roles."),
    phoneNo: yup.string()
  }),
};
