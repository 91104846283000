import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ApiClient } from "./client";
import { SignInDto, AuthControllerSignInResponse } from "./typings";

export const signIn = async (args: SignInDto) => {
  const res = await ApiClient.post<AuthControllerSignInResponse<200>>(
    "/auth/sign-in",
    args
  );
  return res.data;
};

export const useSignIn = (
  options?: Omit<
    UseMutationOptions<AuthControllerSignInResponse<200>, AxiosError<any>, SignInDto>,
    "mutationFn"
  >
) =>
  useMutation({
    mutationFn: signIn,
    ...options,
  });
