import React from "react";
import cl from "classnames";
import { useField } from "formik";

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  toggleSize?: string;
  children: string;
}

export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  ({ toggleSize = "md", children, ...restProps }, ref) => {
    return (
      <label className="relative flex w-fit items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          {...restProps}
          ref={ref}
        />
        <div
          className={cl(
            "bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-400 dark:peer-focus:ring-gray-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all dark:border-gray-600 peer-checked:bg-gray-900",
            toggleSize === "sm" && "w-9 h-5 after:h-4 after:w-4",
            toggleSize === "md" && "w-11 h-6 after:h-5 after:w-5"
          )}
        ></div>

        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {children}
        </span>
      </label>
    );
  }
);

export const FormikToggle = <T extends string | number>(
  props: ToggleProps & { name: string }
) => {
  const [field] = useField<T>({
    name: props.name,
    type: "checkbox"
  });

  return <Toggle {...field} {...props} />;
};
