import { useQuery } from '@tanstack/react-query';
import { Button } from 'components/Button';
import { FormikCombobox } from 'components/Combobox';
import { Form } from 'components/Form';
import { FormikInput } from 'components/Input';
import { FormikToggle } from 'components/Toggle';
import {
  BookingStatuses,
  optionsForTransferTypes,
} from 'constants/bookingConstants';
import { useHasPermission } from 'hooks/useHasPermission';
import { useUpdateEntity } from 'hooks/useUpdateEntity';
import {
  deleteBooking,
  getBooking,
  updateBookingRecord,
} from 'lib/api/bookings';
import { UpdateBookingDto } from 'lib/api/typings';
import { getVehicles } from 'lib/api/vehicles';
import { useCallback } from 'react';
import { AiFillBackward } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

export const UpdateBooking = () => {
  const hasDeletePermission = useHasPermission('delete-booking');
  const hasUpdatePermission = useHasPermission('update-booking');
  const navigate = useNavigate();
  const location = useLocation();
  const urlParts = location.pathname.split('/');
  urlParts.pop();

  const handleBackBtn = useCallback(
    () => navigate(urlParts.join('/')),
    [navigate, urlParts],
  );
  const { data: vehicleData } = useQuery(['vehicle'], getVehicles);

  const {
    isUpdatingRecord,
    error,
    formik,
    handleDeleteButtonClk,
    isCloseButtonDisbaled,
    isDeleteButtonDisabled,
    isDeletingRecord,
    isFormDisabled,
    isLoadingRecord,
    isUpdateButtonDisabled,
    record,
  } = useUpdateEntity<UpdateBookingDto>({
    formConfig: { initialValues: {} },
    entityName: 'booking',
    deleteApi: deleteBooking,
    updateApi: updateBookingRecord,
    fetchPreviousData: getBooking,
  });

  return (
    <Form formik={formik}>
      <div className="grid grid-cols-2 gap-4">
        <FormikInput
          name="from"
          label="Pickup From"
          placeholder="pickup from"
          disabled={true}
        />

        <FormikInput
          name="to"
          label="Drop Off"
          placeholder="Drop Off"
          disabled={true}
        />

        <FormikInput
          name="flightNo"
          label="Flight No"
          placeholder="Flight number"
        />

        <FormikCombobox
          name="status"
          placeholder="Select Status"
          inputProps={{ label: 'Status' }}
          options={BookingStatuses}
          disabled={isFormDisabled}
        />

        <FormikInput disabled={isFormDisabled} name="luggage" label="Luggage" />

        <FormikInput
          disabled={isFormDisabled}
          name="boosterSeat"
          label="Booster Seat"
        />

        <FormikInput
          disabled={isFormDisabled}
          name="passengers"
          label="Passengers"
        />

        <FormikInput
          disabled={isFormDisabled}
          name="babySeats"
          label="Baby Seats"
        />

        <FormikCombobox
          name="type"
          placeholder="Select"
          inputProps={{ label: 'Booking Type' }}
          options={optionsForTransferTypes}
          disabled={isFormDisabled}
        />

        <FormikInput name="dogs" label="Dogs" disabled={isFormDisabled} />

        <FormikInput
          name="distance"
          label="Journey Distance"
          placeholder="total journey distance..."
        />

        <FormikInput
          name="prices.grandTotal"
          label="Grand Total"
          placeholder="Total amount..."
        />

        <FormikInput
          name="prices.subtotal"
          label="Subtotal"
          placeholder="Total amount..."
        />

        <FormikInput name="pickupDate" label="Pickup Date" placeholder="" />
        <FormikInput name="returnDate" label="Return Date" placeholder="" />

        <FormikCombobox
          name="vehicleId"
          placeholder="Select Vehicle"
          inputProps={{ label: 'Vehicle' }}
          options={
            vehicleData?.result?.map((item) => ({
              label: `${item.engineNo}- ${item.company} - ${item.rate}`,
              value: item.id,
            })) || [{ label: '', value: '' }]
          }
          displayValue={(id: string) => {
            const vehicle = vehicleData?.result?.find((opt) => opt.id === id);
            return (
              `${vehicle?.engineNo}- ${vehicle?.company} - ${vehicle?.rate}` ||
              ''
            );
          }}
          disabled={isFormDisabled}
        />
      </div>
      <div className="pt-4">
        <div className="flex flex-col-2 mt-2 gap-3">
          <FormikToggle name="isMeetGreet" disabled={isFormDisabled}>
            Is Meet & Greet
          </FormikToggle>
        </div>
      </div>

      <div className="flex gap-2 items-center mt-6 justify-between">
        <Button
          type="button"
          icon={<AiFillBackward />}
          variant="ghost"
          onClick={handleBackBtn}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="secondary"
          className="text-rose-500"
          icon={<FiTrash />}
          loading={isDeletingRecord}
          onClick={handleDeleteButtonClk}
          disabled={isDeleteButtonDisabled || !hasDeletePermission}
        >
          Delete
        </Button>
        <Button
          type="submit"
          disabled={isUpdateButtonDisabled || !hasUpdatePermission}
          loading={isUpdatingRecord}
        >
          Update
        </Button>
      </div>
    </Form>
  );
};
