import {
  ColumnDef,
  createColumnHelper,
  useReactTable,
} from '@tanstack/react-table';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { VehicleResponseDto } from 'lib/api/typings';
import { useCallback, useMemo, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Table } from 'components/Table';
import { Pagination } from 'components/Pagination';
import { Tag } from 'components/Tag';
import { useSkeletonTable } from 'hooks/createSkeletonTable';
import { NoRecordFound } from 'components/NoRecordFound';
import { useHasPermission } from 'hooks/useHasPermission';
import { Input } from 'components/Input';
import { useDebounce } from 'hooks/useDebounce';
import { useSearchVehicles } from 'lib/api/vehicles';

const columnHelper = createColumnHelper<VehicleResponseDto>();

const columns = [
  columnHelper.accessor('vin', {
    header: 'VIN',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('regNo', {
    header: 'Reg No',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('modelYear', {
    header: 'Model Year',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('company', {
    header: 'Company',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('class', {
    header: 'Class',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('engineNo', {
    header: 'Engine No',
    cell: (val) => val.getValue(),
  }),
  columnHelper.accessor('isActive', {
    header: 'Status',
    cell: (val) => (
      <Tag variant={val.getValue() ? 'success' : 'simple'}>
        {val.getValue() ? 'Active' : 'Inactive'}
      </Tag>
    ),
  }),
];

export const VehiclesListing = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const navigate = useNavigate();
  const { setText, filter } = useDebounce();

  const { skeletonColumns, skeletonRows } = useSkeletonTable(
    columns as ColumnDef<VehicleResponseDto, any>[],
  );
  const hasCreatePermission = !useHasPermission('create-vehicle');

  const onChangePageSize = useCallback(
    (size: number | string) => {
      setPageSize(size as number);
    },
    [setPageSize],
  );

  const { data, isLoading } = useSearchVehicles({
    pageNumber: currentPageNumber,
    pageSize: pageSize,
    sort: {
      createdAt: 'desc',
    },
    filter: {
      ...(filter && {
        OR: [
          {
            engineNo: {
              contains: filter,
            },
          },
          {
            vin: {
              contains: filter,
            },
          },
          {
            regNo: {
              contains: filter,
            },
          },
          {
            company: {
              contains: filter,
            },
          },
        ],
      }),
    },
  });

  const rows = useMemo(
    () => (isLoading ? skeletonRows : data?.result || []),
    [data?.result, isLoading, skeletonRows],
  );

  const handleRowClick = useCallback(
    (row: VehicleResponseDto) => {
      navigate(row.id.toString());
    },
    [navigate],
  );

  const table = useReactTable({
    columns: isLoading ? skeletonColumns : columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  let content: React.ReactNode;

  if (isLoading || rows.length > 0) {
    content = (
      <>
        <Table table={table} onRowClick={handleRowClick} />

        <Pagination
          currentPage={currentPageNumber}
          onPageChange={setCurrentPageNumber}
          pageCount={data?.pageCount || 0}
          total={data?.totalRecords || 0}
          pageSize={pageSize}
          onSizeChange={onChangePageSize}
        />
      </>
    );
  } else {
    content = (
      <div className="bg-gray-50 border">
        <NoRecordFound
          title="No records found."
          description="There are no vehicles in our records, please create one using above button."
        />
      </div>
    );
  }

  return (
    <Card>
      <div className="flex px-3 py-3 items-center justify-between">
        <h4 className="font-bold text-xl">Vehicles</h4>
        <div className="flex gap-3">
          <Input
            placeholder="Search..."
            name="filter"
            onChange={(e: any) => setText(e.target.value)}
          />
          <Link to="new" hidden={hasCreatePermission}>
            <Button icon={<FiPlus />}>New Vehicle</Button>
          </Link>
        </div>
      </div>

      {content}
      <Outlet />
    </Card>
  );
};
