import React from 'react';
import { FormikCombobox } from 'components/Combobox';
import { FormikInput } from 'components/Input';

export const RuleFormFields = ({
  isDisabled = false,
}: {
  isDisabled: boolean;
}) => {
  return (
    <>
      <div className=" grid grid-cols-2 gap-4">
        <FormikCombobox
          name="unit"
          placeholder="Select Base"
          inputProps={{ label: 'Conversion Unit' }}
          options={[
            {
              label: 'Kilometre',
              value: 'KM',
            },
            {
              label: 'Mile',
              value: 'MI',
            },
          ]}
          disabled={isDisabled}
        />

        <FormikInput
          name="price"
          label="Price"
          placeholder="Price based on per conversion"
          disabled={isDisabled}
        />

        <FormikInput
          name="tax"
          label="Tax"
          placeholder="Tax amount"
          disabled={isDisabled}
        />

        <FormikInput
          name="extraFee"
          label="Extra Fee"
          placeholder="Extra Fee if the pickup/drop-off is airport"
          disabled={isDisabled}
        />

        <FormikInput
          name="fixCode"
          label="Promo Code"
          placeholder="Voucher code e.g. ED500"
          disabled={isDisabled}
        />

        <FormikInput
          name="fixAmount"
          label="Voucher Amount"
          placeholder="Discount amount against the voucher..."
          disabled={isDisabled}
        />
      </div>
    </>
  );
};
