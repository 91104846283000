import { ComponentProps } from "react";
import { Oval } from "react-loader-spinner";

type LoaderSize = "text" | "big";

export type LoaderProps = ComponentProps<typeof Oval> & {
  size?: LoaderSize;
};

const SIZE_MAP: Record<
  LoaderSize,
  {
    width: number;
    height: number;
  }
> = {
  text: {
    width: 16,
    height: 16,
  },
  big: {
    width: 30,
    height: 30,
  },
};

export const Loader = ({
  size = "text",
  color = "currentColor",
  secondaryColor = "transparent",
  strokeWidth = 3,
  ...args
}: LoaderProps) => {
  const sizes = SIZE_MAP[size];
  return (
    <Oval {...sizes} color={color} strokeWidth={strokeWidth} secondaryColor={secondaryColor} {...args} />
  );
};
