export default function diff<T extends Record<string, any>>(
  object: T,
  base: T
) {
  const result = {} as T;

  Object.keys(object).forEach((objectKey: keyof T) => {
    if (base[objectKey] !== object[objectKey]) {
      result[objectKey] = object[objectKey];
    }
  }) 

  return result;
}
