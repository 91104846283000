import { Fragment } from 'react';
import cl from 'classnames';
import React from 'react';
import { NavLink, useLocation, useResolvedPath } from 'react-router-dom';
import { Card } from './Card';
import { Transition } from '@headlessui/react';

const SidebarItem = ({
  children,
  to,
  title,
  icon,
}: {
  children?: React.ReactNode;
  to: string;
  icon?: React.ReactNode;
  title?: string;
}) => {
  const { pathname } = useResolvedPath(to);
  const location = useLocation();
  const isActive = location.pathname.startsWith(pathname);

  return (
    <div className="flex flex-col">
      <NavLink
        to={to}
        className={cl(
          'p-2 flex gap-2 items-center w-full cursor-pointer rounded-lg',
          isActive ? 'bg-gray-200' : 'hover:bg-gray-200 active:bg-gray-300',
          children && 'pr-0',
          children && isActive && 'rounded-b-none',
        )}
      >
        {icon}
        {title}
      </NavLink>
      {children && (
        <Transition
          show={!!isActive}
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="p-2 flex flex-col gap-1 rounded-b-lg bg-gray-100">
            {children}
          </div>
        </Transition>
      )}
    </div>
  );
};

export const Sidebar = (props: React.PropsWithChildren<object>) => {
  return (
    <Card className="w-1/5 p-3">
      <ul className="flex flex-col gap-1">{props.children}</ul>
    </Card>
  );
};

Sidebar.Item = SidebarItem;
