import { Modal, useModal, useModalOnClose } from "components/Modal";
import { useEffect } from "react";
import { Form } from "components/Form";
import { Button } from "components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRole } from "lib/api/roles";
import { CreateRoleDto } from "lib/api/typings";
import { ApiErrorAlert } from "components/ApiErrorAlert";
import { RolesForm } from "./RolesFormFields";
import { useFormik } from "formik";
import { RolesFormConfig } from "./RolesConfig";

export const NewRoles = Modal.create(
  () => {
    const { close } = useModal();
    const queryClient = useQueryClient();
    const {
      mutateAsync: createRoleMutation,
      isLoading: isCreatingRole,
      error,
    } = useMutation(createRole, {
      onSuccess: () => queryClient.invalidateQueries(["role"]),
    });

    const form = useFormik<CreateRoleDto>({
      ...RolesFormConfig,
      async onSubmit(data) {
        await createRoleMutation(data);
        close(true);
      },
    });

    useModalOnClose(async () => {
      if (isCreatingRole) return false;

      if (form.dirty) {
        return Modal.confirm({});
      }

      return true;
    });

    const { setFieldValue } = form;

    useEffect(() => {
      setFieldValue("slug", form.values.name.replace(/\s/g, "-").toLowerCase());
    }, [setFieldValue, form.values.name]);

    return (
      <Form formik={form}>
        <Modal.Title>Create new Role</Modal.Title>

        <div className="p-4">
          <ApiErrorAlert error={error} />

          <RolesForm isDisabled={isCreatingRole} />
        </div>

        <Modal.Actions>
          <Modal.CloseButton disabled={isCreatingRole}></Modal.CloseButton>
          <Button type="submit" loading={isCreatingRole}>
            Create
          </Button>
        </Modal.Actions>
      </Form>
    );
  },
  { size: "lg" }
);
