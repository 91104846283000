import { useQueryClient } from "@tanstack/react-query";
import { Menu } from "components/Menu";
import { User } from "components/User";
import { useStoreActions, useUser } from "GlobalStore";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../components/Logo";
import { Breadcrumbs } from "./Breadcrumbs";
import { MainMenu } from "./MainMenu";

export const Header = () => {
  const { logout } = useStoreActions();
  const user = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleOnLogout = () => {
    queryClient.invalidateQueries();
    logout();  
    navigate("/app"); 
  };

  return (
    <>
      <header className="py-3 fixed w-full z-10 bg-white border-b">
        <div className="max-w-7xl px-2 w-full mx-auto flex justify-between items-center">
          <Logo />
          <div className="flex gap-6">
            <MainMenu />
            <div className="border-l"></div>
            <Menu>
              <Menu.Trigger>
                <div className="cursor-pointer  hover:bg-gray-100 rounded-lg px-2 p-1">
                  <User user={user} />
                </div>
              </Menu.Trigger>
              <Menu.Content>
                <Link to="/app/settings">
                  <Menu.Item icon={<FiSettings />}>Settings</Menu.Item>
                </Link>
                <Menu.Separator />
                <Menu.Item icon={<FiLogOut />} onClick={handleOnLogout}>
                  Logout
                </Menu.Item>
              </Menu.Content>
            </Menu>
          </div>
        </div>
      </header>
      <div className="h-[68px]"></div>
      <Breadcrumbs />
    </>
  );
};
