import { Listbox as ListBoxButton, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import cl from 'classnames';

interface ListboxOption<T> {
  value: T;
  label: React.ReactNode;
}

interface ListboxProps<T> {
  name: string;
  value: string | number;
  changePageSize: (size: string | number) => void;
  options: ListboxOption<T>[];
}

export const Listbox = <T extends object | any>(props: ListboxProps<T>) => {
  return (
    <div className="relative">
      <ListBoxButton
        defaultValue={props.value}
        onChange={props.changePageSize}
        name={props.name}
      >
        <div className="relative mt-1">
          <ListBoxButton.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:bg-primary sm:text-sm">
            {({ open }) => (
              <>
                <span className="block truncate">{props.value}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <FiChevronDown
                    className={cl(
                      'transition-all h-full',
                      open && 'rotate-y-180',
                    )}
                    aria-hidden="true"
                  />
                </span>
              </>
            )}
          </ListBoxButton.Button>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <ListBoxButton.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {props.options.map((option, index) => (
                <ListBoxButton.Option
                  key={index}
                  className={({ active }) =>
                    `flex flex-col cursor-default py-2 items-center justify-center ${
                      active ? 'bg-primaryHover text-white' : 'text-gray-900'
                    }`
                  }
                  value={option.value}
                >
                  {option.label}
                </ListBoxButton.Option>
              ))}
            </ListBoxButton.Options>
          </Transition>
        </div>
      </ListBoxButton>
    </div>
  );
};
