import { Button } from "components/Button";
import { FormikInput } from "components/Input";
import { Logo } from "components/Logo";
import { useFormik } from "formik";
import { useCallback } from "react";
import { Form } from "components/Form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { updatePassword } from "lib/api/user";
import { Alert } from "components/Alert";
import { UpdatePasswordDto } from "lib/api/typings";
import { ApiErrorAlert } from "components/ApiErrorAlert";
import { FiEye } from "react-icons/fi";
import { useState } from "react";

export const ResetPassword = () => {
  const param = useParams();
  const token = param.token || "";
  const [type, setType] = useState<Record<string, string>>({
    password: "password",
    confirm: "password",
  });

  const {
    mutateAsync: updateMutate,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useMutation(updatePassword);

  const onSubmit = useCallback(
    (values: UpdatePasswordDto) => {
      updateMutate({ token: token, password: values.password });
    },
    [token, updateMutate]
  );

  const onClickPassword = () => {
    setType((prev) => {
      return {
        password: prev.password === "password" ? "text" : "password",
        confirm: prev.confirm,
      };
    });
  };

  const onClickConfirm = () => {
    setType((prev) => {
      return {
        confirm: prev.confirm === "password" ? "text" : "password",
        password: prev.password,
      };
    });
  };

  const formik = useFormik<any>({
    initialValues: {
      password: "",
      token: "",
      confirm: "",
    },
    validationSchema: yup.object().shape({
      password: yup.string().trim().required("Please enter a password"),
      confirm: yup
        .string()
        .trim()
        .when("password", (password, schema) => {
          return schema.test({
            message: "Should match the password field.",
            test: (pass: string, context: any) => {
              return pass !== undefined && pass === context.parent.password;
            },
          });
        }),
    }),
    onSubmit,
  });

  return (
    <div className="flex justify-center h-screen flex-1 items-center">
      <Form formik={formik}>
        <div className="flex gap-4 flex-col p-8 rounded-lg bg-white border w-96">
          <Logo />
          {isError ? (
            <ApiErrorAlert error={error} />
          ) : (
            isSuccess && (
              <Alert
                type="success"
                title="Success"
                description="Your password was reset."
              />
            )
          )}

          <FormikInput
            placeholder="***********"
            name="password"
            type={type.password}
            label="Password"
            autoComplete="on"
            disabled={isLoading}
            suffixIcon={
              <FiEye
                id="password"
                onClick={onClickPassword}
              />
            }
          />

          <FormikInput
            placeholder="***********"
            name="confirm"
            type={type.confirm}
            label="Confirm Password"
            autoComplete="on"
            disabled={isLoading}
            suffixIcon={
              <FiEye id="confirm" onClick={onClickConfirm} />
            }
          />

          <Button loading={isLoading} type="submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};
