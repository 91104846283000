import { ApiErrorAlert } from "components/ApiErrorAlert";
import { Button } from "components/Button";
import { Form } from "components/Form";
import { Loader } from "components/Loader";
import { Modal, useModal, useModalOnClose } from "components/Modal";
import { useHasPermission } from "hooks/useHasPermission";
import { useUpdateEntity } from "hooks/useUpdateEntity";
import { deleteRole, getRoleById, updateRole } from "lib/api/roles";
import { PermissionResponseDto, UpdateRoleDto } from "lib/api/typings";
import { useCallback } from "react";
import { FiTrash } from "react-icons/fi";
import { RolesFormConfig } from "./RolesConfig";
import { RolesForm } from "./RolesFormFields";

export const UpdateRoles = Modal.create(
  () => {
    const { close } = useModal();
    const getRoles = useCallback((id: number) => {
      return getRoleById(id).then((data) => ({
        ...data,
        permissions: data.permissions?.map((perm: PermissionResponseDto) => perm.slug) || [],
      }));
    }, []);

    const {
      isUpdatingRecord,
      error,
      formik,
      handleDeleteButtonClk,
      isCloseButtonDisbaled,
      isDeleteButtonDisabled,
      isDeletingRecord,
      isFormDisabled,
      isLoadingRecord,
      isUpdateButtonDisabled,
    } = useUpdateEntity<UpdateRoleDto>({
      formConfig: RolesFormConfig,
      entityName: "role",
      deleteApi: deleteRole,
      updateApi: updateRole,
      fetchPreviousData: getRoles,
      onDelete: () => close(true),
      onUpdate: () => close(true),
    });

    const hasUpdatePermission = useHasPermission("update-role");
    const hasDeletePermission = useHasPermission("delete-role");

    useModalOnClose(() => {
      if (isUpdatingRecord) return false;

      if (formik.dirty) return Modal.confirm({});

      return true;
    });

    return (
      <Form formik={formik}>
        <Modal.Title>
          <div className="flex gap-2 items-center">
            {isLoadingRecord && <Loader />}
            Update Role
          </div>
        </Modal.Title>
        <div className="p-4">
          <ApiErrorAlert error={error} />

          <RolesForm isDisabled={isFormDisabled} />
        </div>
        <Modal.Actions>
          <Modal.CloseButton
            disabled={isCloseButtonDisbaled}
          ></Modal.CloseButton>
          <div className="flex gap-2 items-center">
            <Button
              type="button"
              variant="secondary"
              className="text-rose-500"
              icon={<FiTrash />}
              loading={isDeletingRecord}
              onClick={handleDeleteButtonClk}
              disabled={isDeleteButtonDisabled || !hasDeletePermission}
            >
              Delete
            </Button>
            <Button
              type="submit"
              disabled={isUpdateButtonDisabled || !hasUpdatePermission}
              loading={isUpdatingRecord}
            >
              Update
            </Button>
          </div>
        </Modal.Actions>
      </Form>
    );
  },
  { size: "lg" }
);
