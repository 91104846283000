/* eslint-disable @typescript-eslint/ban-types */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConfirmModalProps, Modal } from 'components/Modal';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import diff from 'lib/diff';
import { FormikConfig, useFormik } from 'formik';

export interface UpdateModalProps<ValueType> {
  formConfig: Omit<FormikConfig<ValueType>, 'onSubmit'>;
  cacheKey?: string;
  entityName: string;
  secondaryEntityName?: string;
  fetchPreviousData: (id: any) => Promise<ValueType | any>;
  deleteApi: (id: any) => Promise<void | any>;
  updateApi: (props: { id: any; data: ValueType }) => Promise<void | any>;
  onDelete?: (payload: any) => void;
  onUpdate?: (updated: any) => void;
  onBeforeUpdate?: (payload: any) => void;
  entityId?: number;
  deleteConfirmProps?: ConfirmModalProps;
  onDeleteRecordError?: () => void;
  onConfirmationSuccess?: () => void;
}

export const useUpdateEntity = <T extends {}, R = any>(
  props: UpdateModalProps<T>,
) => {
  const params = useParams();
  const secondaryCacheKey = props.secondaryEntityName?.toLowerCase();
  const cacheKey = props.cacheKey || props.entityName?.toLowerCase();
  const queryClient = useQueryClient();
  const id = props.entityId || (params.id as string | number);

  const {
    data: record,
    isLoading: isLoadingRecord,
    refetch,
  } = useQuery([cacheKey, id], () => props.fetchPreviousData(id), {
    enabled: !!params.id,
  });

  const onSuccessfulMutation = useCallback(() => {
    if (secondaryCacheKey) {
      queryClient.invalidateQueries([secondaryCacheKey]);
    } else {
      queryClient.invalidateQueries([cacheKey, id]);
    }
  }, [cacheKey, queryClient, secondaryCacheKey, id]);

  const {
    isLoading: isDeletingRecord,
    mutateAsync: deleteRecord,
    error: deleteError,
  } = useMutation(props.deleteApi, { onSuccess: onSuccessfulMutation });

  const {
    isLoading: isUpdatingRecord,
    mutateAsync: update,
    error: createError,
  } = useMutation(props.updateApi, { onSuccess: onSuccessfulMutation });

  const error = deleteError || createError;

  const formik = useFormik<T>({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    ...props.formConfig,
    initialValues: record ? { ...record } : props.formConfig.initialValues,
    async onSubmit(data) {
      if (record) {
        const payload = diff(data, record);

        if (Object.keys(payload).length < 1) {
          return;
        }
        props.onBeforeUpdate?.(payload);
        const newData = await update({
          id,
          data: payload,
        });

        props.onUpdate?.(newData);
      }
    },
  });

  const handleDeleteButtonClk = async () => {
    const shouldDelete = await Modal.confirm(
      props.deleteConfirmProps || {
        title: `Delete`,
        description: 'Are you sure you want to delete this record?',
        confirmButtonVariant: 'danger',
      },
    );

    if (shouldDelete) {
      props.onConfirmationSuccess?.();
      const result = await deleteRecord(id).catch((err) => {
        props.onDeleteRecordError?.();
        throw err;
      });

      props.onDelete?.(result);
    }
  };

  return {
    formik,
    handleDeleteButtonClk,
    error,
    isFormDisabled: isUpdatingRecord || isLoadingRecord || isDeletingRecord,
    isDeletingRecord,
    isUpdatingRecord,
    isLoadingRecord,
    record: record as R,
    isUpdateButtonDisabled: !formik.dirty,
    isDeleteButtonDisabled: isUpdatingRecord,
    isCloseButtonDisbaled: isUpdatingRecord || isDeletingRecord,
  };
};
